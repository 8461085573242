import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex-center fs-12" }
const _hoisted_2 = { class: "fs-12 flex-center" }
const _hoisted_3 = {
  key: 0,
  class: "fw-700",
  style: {"color":"#262523"}
}
const _hoisted_4 = { class: "fw-700" }
const _hoisted_5 = { style: {"color":"#696c80"} }
const _hoisted_6 = { style: {"margin-left":"6.4rem"} }
const _hoisted_7 = {
  class: "flex-center ml-auto",
  style: {"justify-content":"flex-end"}
}
const _hoisted_8 = {
  class: "fs-12 flex-center",
  style: {"color":"#696c80"}
}
const _hoisted_9 = {
  class: "fw-700 pr-4",
  style: {"color":"#262523"}
}
const _hoisted_10 = { class: "flex-center ml-auto" }
const _hoisted_11 = {
  key: 2,
  class: "page-number"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 3,
  class: "page-number"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.totalData)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.currentPage * _ctx.dataPerPage - (_ctx.dataPerPage - 1)) + "- ", 1))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.currentPage * _ctx.dataPerPage > _ctx.totalData
            ? _ctx.totalData
            : _ctx.currentPage * _ctx.dataPerPage) + " ", 1),
      _createElementVNode("span", _hoisted_5, " of " + _toDisplayString(_ctx.totalData) + " Results ", 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_el_dropdown, null, {
            dropdown: _withCtx(() => [
              _createVNode(_component_el_dropdown_menu, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_dropdown_item, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleCommand(10)))
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode(" 10 ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_dropdown_item, {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleCommand(25)))
                  }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode(" 25 ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_dropdown_item, {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleCommand(50)))
                  }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode(" 50 ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_dropdown_item, {
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleCommand(100)))
                  }, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createTextVNode(" 100 ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.dataPerPage), 1),
                _cache[8] || (_cache[8] = _createTextVNode(" per Page ")),
                _cache[9] || (_cache[9] = _createElementVNode("span", {
                  class: "fs-8 fw-900 pt-2 pl-4",
                  style: {"color":"#262523"}
                }, "▼", -1))
              ])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      (_ctx.currentPage !== 1 && _ctx.pages !== 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "arrow arrow-left",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changePage(_ctx.currentPage - 1)))
          }))
        : _createCommentVNode("", true),
      (_ctx.currentPage > 3)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changePage(1))),
            class: "page-number"
          }, " 1 "))
        : _createCommentVNode("", true),
      (_ctx.currentPage > 3 && _ctx.pages > 5)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, "..."))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "page flex-center",
          key: page
        }, [
          (page >= _ctx.currentPage - 2 && page <= _ctx.currentPage + 2)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                onClick: ($event: any) => (_ctx.changePage(page)),
                class: _normalizeClass(["page-number", { active: page === _ctx.currentPage }])
              }, _toDisplayString(page), 11, _hoisted_12))
            : _createCommentVNode("", true)
        ]))
      }), 128)),
      (_ctx.currentPage < _ctx.pages - 2 && _ctx.pages > 5)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, " ... "))
        : _createCommentVNode("", true),
      (_ctx.currentPage < _ctx.pages - 2)
        ? (_openBlock(), _createElementBlock("div", {
            key: 4,
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.changePage(_ctx.pages))),
            class: "page-number"
          }, _toDisplayString(_ctx.pages), 1))
        : _createCommentVNode("", true),
      (_ctx.currentPage !== _ctx.pages && _ctx.pages !== 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 5,
            class: "arrow arrow-right",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.changePage(_ctx.currentPage + 1)))
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}