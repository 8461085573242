import {
  getDatabase,
  ref,
  query,
  get,
  set,
  push,
  child,
} from "firebase/database";
import { getAuth } from "firebase/auth";
import router from "@/router";
import format from "date-fns/format";
import helpers from "@/helpers/global";
import _ from "lodash";
import { Auth, Provider, SignupFormDetails, User } from "../types";
const DATETIME_FORMAT = "yyy-MM-dd HH:mm:ss";

const itemKey = "signupFormDetails";

export default {
  namespaced: true,
  state: {
    user: null,
    error: null,
    selectedProvider: null,
    email: null,
    loading: false,
    signupFormDetails: null,
    redirectUrl: "/",
  },
  mutations: {
    setUserLogin(state: Auth, user: User): any {
      state.user = user;
    },
    errorMessage(state: Auth, error: string): any {
      state.error = error;
    },
    selectedProvider(state: Auth, selectedProvider: Provider): any {
      state.selectedProvider = selectedProvider;
    },
    setEmail(state: Auth, email: string): any {
      state.email = email;
    },
    loading(state: { loading: boolean }, loading: boolean): any {
      state.loading = loading;
    },
    signupFormDetails(state: Auth, signupFormDetails: SignupFormDetails): any {
      state.signupFormDetails = signupFormDetails;
    },
    redirectUrl(state: Auth, redirectUrl: string): any {
      state.redirectUrl = redirectUrl;
    },
  },
  getters: {
    user(state: Auth): any {
      return state.user;
    },
    error(state: Auth): string {
      return state.error;
    },
    selectedProvider(state: Auth): Provider {
      return state.selectedProvider;
    },
    email(state: Auth): string {
      return state.email;
    },
    signupFormDetails(state: Auth): SignupFormDetails {
      return state.signupFormDetails;
    },
    redirectUrl(state: Auth): string {
      return state.redirectUrl;
    },
  },
  actions: {
    setUserLogin({ commit }: { commit: any }): any {
      const userData: any = localStorage.getItem("userData");
      if (userData) {
        try {
          // commit("setUserLogin", JSON.parse(atob(userData) || "{}"));
          commit(
            "setUserLogin",
            JSON.parse(
              decodeURIComponent(escape(atob(String(userData)))) || "{}"
            )
          );
        } catch (error) {
          const auth = getAuth();
          auth.signOut();
          localStorage.removeItem("userData");
          commit("setUserLogin", null);

          // @todo redirect to page by user's role
          router.replace("/login");
        }
      }
    },
    clearError({ commit }: { commit: any }): any {
      commit("errorMessage", null);
    },
    async addLogs({ commit }: { commit: any }): Promise<any> {
      // let ip = "";
      // const url = "https://api.ipify.org?format=json"
      // await axios.get(url).then(response => {
      //   ip = response.data.ip
      // })
      // .catch(function (error) {
      //     console.log(error);
      // });
      // object.ip = ip;
      // await firebase.firestore().collection("logs").add(object);
      // return true;
      // console.log(object);
      commit("errorMessage", null);
    },
    async loginByOtp(
      { commit, dispatch }: { commit: any; dispatch: any },
      params: { email: string; pincode: string }
    ): Promise<any> {
      const email = helpers.filterEmail(params.email);
      const emailKey = helpers.filterPath(email);
      // const emailKey = helpers.filterPath("a@b.com");

      const db = getDatabase();
      const path = `login_send_email_web/${emailKey}`;
      const data = (await get(query(ref(db, path)))).val();
      const result = _.isEmpty(data) ? false : data.pincode == params.pincode;

      if (!result) {
        commit("errorMessage", "Invalid email or OTP");
        return false;
      }

      commit("errorMessage", "");
      dispatch("loginAsUser", { email: params.email });
      return true;
    },
    async loginAsUser(
      {
        commit,
        dispatch,
        getters,
        rootState,
      }: { commit: any; dispatch: any; getters: any; rootState: any },
      { email, callback }: { email: string; callback?: any }
    ): Promise<any> {
      email = helpers.filterEmail(email);
      const emailKey = helpers.filterPath(email);

      // const user = (await firebase.database().ref(`/users/${emailKey}`).once("value")).val()
      const db = getDatabase();
      const path = `users/${emailKey}`;
      const user = (await get(query(ref(db, path)))).val();
      const createdAt = format(new Date(), DATETIME_FORMAT);

      // @todo if email is mobile phone number

      if (!_.isEmpty(user)) {
        const userData = {
          email: user.email,
          firstname: user.firstName,
          lastname: user.lastName,
          displayName: user.displayName,
          companyName: user.companyName,
          companyLogoUrl: user.companyLogourl,
          color: user.color,
          photoUrl: user.photoURL,
          coreValues: user.coreValues || [],
          typeOfOrg: user.typeOfOrg || "",
          missionAndVision: user.missionAndVision || "",
          autoGenerateForm: user.autoGenerateForm || false,
          createdAt: createdAt,
        };
        localStorage.setItem(
          "userData",
          btoa(unescape(encodeURIComponent(JSON.stringify(userData))))
        );

        // @todo log access data
        // commit("errorMessage", "");
        commit("loading", false);

        // @todo route to default page
        const redirectUrl = getters["redirectUrl"] || "/";

        // @fixed save coreValues if came from analyzer
        if (rootState.coreValuesAnalyzer) {
          if (rootState.coreValuesAnalyzer.doSaveValues) {
            const values = rootState.coreValuesAnalyzer.values;
            if (values) {
              commit("setUserLogin", userData);
              await dispatch("coreValuesAnalyzer/saveValues", values, {
                root: true,
              });
            }
          }
        }

        // delay for update credits from cloud function
        if (callback && _.isFunction(callback)) {
          callback();
        } else {
          const delay = 1500;
          setTimeout(() => {
            window.location.href = redirectUrl;
          }, delay);
        }
      } else {
        console.log(`Not found user email: ${email}`);
      }

      /*
      // @see src\store\core\authen.ts
      const emailKey = targetEmail.replace(/\./g, "||").toLowerCase()
      const user = (await firebase.database().ref(`/users/${emailKey}`).once("value")).val()
      const company = (await firebase.database().ref(`/company/${user.company}`).once("value")).val()
      const userData = {
        company_name: company.name,
        company: user.company,
        firstname: user.firstname,
        lastname: user.lastname,
        user_type: user.user_type,
        email: user.email,
        profilepic: (user.profilepic) ? user.profilepic : "",
        profilepic_crop: (user.profilepic_crop) ? user.profilepic_crop : "",
        profilepic_small: (user.profilepic_small) ? user.profilepic_small : ""
      }

      localStorage.setItem("userData", btoa(JSON.stringify(userData)))

      dispatch("authen/addLogs", {
        createdate: format(new Date(), "YYYY-MM-DD HH:mm:ss"),
        status: "login",
        user: user.email
      }, { root: true }).then(function () {
        setTimeout(() => {
          // console.log("loginAsUser(): success")
          // commit("loadingStatus", false)

          // @fixed prevent error from many components
          // @todo  use route instead redirect
          // @see   router.replace("/")

          // @todo redirect IS NOT elegant display
          const redirectUrl = user.user_type == "admin" ? "/members" : "/"
          window.location.href = redirectUrl
          // router.replace(redirectUrl)

        }, 500)
      })
      //*/
    },
    async login(
      { dispatch }: { dispatch: any },
      authData: { email: string; provider: string }
    ): Promise<any> {
      const provider = authData.provider;
      dispatch(`${provider}/login`, authData, { root: true });
    },
    async signup(
      { dispatch }: { dispatch: any },
      authData: { email: string; provider: string }
    ): Promise<any> {
      const provider = authData.provider;
      dispatch(`${provider}/signup`, authData, { root: true });
    },
    logout({ commit }: { commit: any }): any {
      // unregister all service worker(s)
      if (window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            for (const registration of registrations) {
              registration.unregister();
            }
          });
      }

      const auth = getAuth();

      auth.signOut();

      localStorage.removeItem("userVersion");
      localStorage.removeItem("userData");
      commit("setUserLogin", null);

      // @todo config of page after logout
      router.replace("/");
    },
    async requestLoginWeb(
      { commit, dispatch }: { commit: any; dispatch: any },
      loginWebRequest: {
        email: string;
      }
    ): Promise<any> {
      const email = helpers.filterEmail(loginWebRequest.email);
      const emailKey = helpers.filterPath(email);

      /*
       * @todo validate eamil
       * @see https://trello.com/c/1JuD1WaP/1290-assessment-as-a-user-i-want-to-be-able-to-log-in-to-my-account-so-that-i-can-manage-my-assessments
       *
       * 1. If user signed up using an email address method but tries to login using Google or Microsoft Authen, allow the user to switch login method
       * 2. If user signed up using Google or Microsoft Authen but tries to login using normal email, display an error message
       */

      const db = getDatabase();

      // google
      const googleUserPath = `oauth_users/google/${emailKey}`;
      const googleUser = (await get(query(ref(db, googleUserPath)))).val();
      if (googleUser) {
        commit(
          "errorMessage",
          "Please login using the same login method that you signed up with."
        );
        return false;
      }

      // @todo check microsoft. Can't checking now because of email format
      // ex: rcoco66_hotmail||com||ext||@rcoco66hotmail||onmicrosoft||com
      const microsoftUserPath = `oauth_users/microsoft/${emailKey}`;
      const microsoftUser = (
        await get(query(ref(db, microsoftUserPath)))
      ).val();
      if (microsoftUser) {
        commit(
          "errorMessage",
          "Please login using the same login method that you signed up with."
        );
        return false;
      }

      // has user in system
      const hasUser = await dispatch("user/hasUser", email, { root: true });
      if (!hasUser) {
        commit("errorMessage", "Please recheck your login credentials");
        return false;
      }

      const createdAt = helpers.now();
      const $loginWebRequest = {
        ...loginWebRequest,
        createdAt: createdAt,
      };

      // send the request to server for get OTP
      const otpPath = `loginweb`;
      const otpKey = push(child(ref(db), otpPath)).key;

      let result = false;
      await set(ref(db, "loginweb/" + otpKey), $loginWebRequest)
        .then(() => {
          // Data saved successfully!
          // console.log("Data saved successfully!");
          commit("setEmail", email);
          commit("errorMessage", null);
          result = true;
        })
        .catch((error) => {
          // The write failed...
          console.log(error, "error");
        });

      return result;
    },
    // @todo move to sign up store
    async requestSignupWeb(
      { commit }: { commit: any },
      {
        email,
      }: {
        email: string;
      }
    ): Promise<any> {
      const emailKey = helpers.filterPath(helpers.filterEmail(email));
      const db = getDatabase();
      const path = `users/${emailKey}`;
      const user = (await get(query(ref(db, path)))).val();
      if (user) {
        const error = `this email (${email}) has already been registered`;
        commit("errorMessage", error);
        console.error(error);
        return;
      }
      commit("errorMessage", "");

      // send the request to server for get OTP
      const otpPath = `signupweb`;
      const otpKey = push(child(ref(db), otpPath)).key;

      const createdAt = helpers.now();
      const $signupWebRequest = {
        email: email,
        createdAt: createdAt,
      };

      await set(ref(db, otpPath + "/" + otpKey), $signupWebRequest)
        .then(() => {
          // Data saved successfully!
          commit("setEmail", email);
          commit("errorMessage", "");
        })
        .catch((error) => {
          // The write failed...
          commit("errorMessage", error);
          console.error(error);
        });
    },
    // @todo move to sign up store
    async verifySignupOtp(
      { commit }: { commit: any },
      params: { email: string; pincode: string }
    ): Promise<any> {
      const email = helpers.filterEmail(params.email);
      const emailKey = helpers.filterPath(email);

      const db = getDatabase();
      const path = `signup_send_email_web/${emailKey}`;
      const data = (await get(query(ref(db, path)))).val();
      const result = _.isEmpty(data) ? false : data.pincode == params.pincode;

      if (!result) {
        commit("errorMessage", "Invalid email or OTP");
        return false;
      }

      commit("errorMessage", "");
      return true;
    },
    // @todo move to sign up store
    initSignupFormDetails({
      state,
      commit,
    }: {
      state: Auth;
      commit: any;
    }): any {
      const signupFormDetails = state.signupFormDetails;
      if (_.isEmpty(signupFormDetails)) {
        if (localStorage.getItem(itemKey)) {
          const restoredData = JSON.parse(
            decodeURIComponent(
              escape(atob(String(localStorage.getItem(itemKey))))
            ) || "{}"
          );
          if (!_.isEmpty(restoredData)) {
            commit(itemKey, restoredData);
          }
        }
      }
    },
    // @todo move to sign up store
    clearSignupFormDetails({ commit }: { commit: any }): any {
      commit(itemKey, null);
      localStorage.removeItem(itemKey);
    },
    // @todo move to sign up store
    async applySignupFormDetails(
      { state, commit }: { state: Auth; commit: any },
      formDetails: SignupFormDetails
    ): Promise<any> {
      let restoredData = {};
      if (localStorage.getItem(itemKey)) {
        restoredData = JSON.parse(
          decodeURIComponent(
            escape(atob(String(localStorage.getItem(itemKey))))
          ) || "{}"
        );
      }

      const signupFormDetails = _.merge(
        restoredData,
        state.signupFormDetails || {},
        formDetails
      );
      commit("signupFormDetails", signupFormDetails);

      localStorage.setItem(
        "signupFormDetails",
        btoa(unescape(encodeURIComponent(JSON.stringify(signupFormDetails))))
      );
    },
    // @todo move to sign up store
    async setSignupEmail(
      { dispatch }: { dispatch: any },
      formDetails: SignupFormDetails
    ): Promise<any> {
      dispatch("applySignupFormDetails", formDetails);
    },
    // @todo move to sign up store
    async setSignupInfo(
      { dispatch }: { dispatch: any },
      formDetails: SignupFormDetails
    ): Promise<any> {
      dispatch("applySignupFormDetails", formDetails);
    },
    // @todo move to sign up store
    async setSignupBranding(
      { dispatch }: { dispatch: any },
      formDetails: SignupFormDetails
    ): Promise<any> {
      dispatch("applySignupFormDetails", formDetails);
    },
    async setLoginEmail(
      { commit }: { commit: any },
      formDetails: { email: string }
    ): Promise<any> {
      commit("setEmail", formDetails.email);
      localStorage.setItem("loginEmail", formDetails.email);
    },
  },
};
