import {
  getDatabase,
  ref,
  get,
  query,
  orderByChild,
  equalTo,
  child,
  set,
} from "firebase/database";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  query as $query,
  collection,
  where,
  orderBy,
  getDocs,
  deleteDoc,
  deleteField,
  onSnapshot,
} from "firebase/firestore";

import _, { sum } from "lodash";
import helpers from "@/helpers/global";
import store from "..";
interface Candidate {
  email: string;
  firstname: string;
  lastname: string;
}

interface InviteSendEmailWeb {
  actionUrl: string;
  email: string;
  formId: string;
}

interface FormTemplate {
  form_name: string;
  objective: string;
}

interface Assessment {
  email: string;
  form_id: string;
  action_url: string;
}

interface Pagination {
  hasPreviouse: boolean;
  hasNext: boolean;
  previous: any;
  next: any;
}
interface FormDetailData {
  code: string;
  statusSectionAccep: boolean;
  statusSectionOAICA: boolean;
  statusSectionValue: boolean;
  statusSectionWork: boolean;
  statusSectionLogic: boolean;
  statusSectionSurvey: boolean;
}
interface FormSectionTimes {
  code: string;
  timeSectionAccep: any;
  timeSectionOAICA: any;
  timeSectionValue: any;
  timeSectionWork: any;
  timeSectionHumanSkills: any;
  timeSectionLogicTest: any;
  timeSectionSurvey: any;
}

const OPENAI_MODEL_DEFAULT = "gpt-4";

const DEFAULT_PARAMS = {
  model: OPENAI_MODEL_DEFAULT,
  temperature: 0.1,
  max_tokens: 1400,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,
};

const PROMPT_ROLE_SYSTEM = "You are a skills coach.";

const PROMPT_ROLE_USER_HUMAN_SKILLS =
  'Based on the responses to the questions, "Describe a time when you had to solve a complex problem at work. What was your thought process, and how did you arrive at a solution?" and "Imagine you are working on a project with a tight deadline, and you discover a significant flaw in the project\'s design. What steps do you take to address the issue, and how do you ensure the project stays on track?" assess their written communication, empathy, situational awareness, and critical thinking ability with a score out of 100, and an explanation for each. Shallow, non-specific, generic responses should get low scores. Additionally, evaluate how the company values (courage to fail, initiative-making, responsiveness, and excellence) are expressed with a score out of 100, and an explanation for each. “<%= response_1 %>” <%= response_2 %> Strictly respond with a JSON Object {human_skills: { written_communication { score, full_explanation }, empathy { score, full_explanation }, situational_awareness { score, full_explanation }, critical_thinking { score, full_explanation }, core_values: { core_value { score, full_explanation } }';

const request = async function (params: { messages: any[] }) {
  const params_: any = { ...DEFAULT_PARAMS, ...params };

  const endpoint = "https://api.openai.com/v1/chat/completions";
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + String(process.env.VUE_APP_OPENAI_API_KEY),
    },
    body: JSON.stringify(params_),
  };

  const response = await fetch(endpoint, requestOptions);
  const data = await response.json();
  return data.choices[0].message.content;
};

const decimalFormat = (num: any) => {
  return Math.round(num * 100) / 100;
};

const hasOwnProperty = (object: any, property: string) => {
  return Object.prototype.hasOwnProperty.call(object, property);
};

const translate = (obj: any, attr: string, lang: string) => {
  // @note hold all this issue waiting all translation
  // const lang = (this as any).$i18n.getLocale(0) || "en";
  let label = "";
  if (_.isObject(obj)) {
    if (lang != "en") {
      attr = attr + "_" + lang;
    }
    if (Object.prototype.hasOwnProperty.call(obj, attr)) {
      label = (obj as any)[attr];
    }
  }
  return label;
};

// scenarioData: [
//   {
//     id: "soelja-5ae58x-a41ed",
//     title: "Scenario 1",
//     description: "Regularly showing up 5-min late to work",
//     matchingStatus: true,
//     companyValueLabel: "Frowned Upon",
//     companyStatus: "green",
//     userValueLabel: "Frowned Upon",
//     userStatus: "green",
//   },

const uiAssembler = {
  getStatusByValue(value: number): string {
    // @see https://trello.com/c/JjyWieBE/1343-assessment-as-a-company-i-want-to-see-how-the-candidates-culture-profile-overlaps-with-the-companys-so-that-i-can-visualize-and
    // https://docs.google.com/spreadsheets/d/10A_WjQigd_8r-5RHca1fKqZBFntlMpa7TdCH-p0_C7s/edit#gid=1207353246
    value = Math.round(value);
    if (value > 79) {
      return "green";
    } else if (value > 59) {
      return "yellow";
    } else {
      return "red";
    }
  },
  getColorOfValuesAndTraitsValue(value: number): string {
    let status = "yellow";
    if (value > 3) {
      status = "green";
    } else if (value < 2) {
      status = "red";
    }
    // console.log(`value: ${value}, status: ${status}`);
    return status;
  },
  // because it is also used in status, not only color fo user
  getUserColorOfValuesAndTraitsValue(value: number): string {
    return "one,two,three,four,five".split(",")[value - 1];
  },
  transformValuesAndTraits(
    formTemplate: any,
    data: any,
    scenarios: any[]
  ): any[] {
    // console.log("transformValuesAndTraits()");
    const summaries = data.summaries || {};
    // console.log(summaries, "summaries");
    for (const i in scenarios) {
      const scenario = scenarios[i];
      const questions = scenarios[i].questions;

      if (formTemplate.values_and_traits_is_custom) {
        const defaultQuestion = {
          checked: false,
          dirty: false,
          id: "",
          name: "",
          option: [
            { value: 1, label: "1" },
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "5" },
          ],
          order: 9999,
          title: "",
          title_th: "",
          type: "choice ",
          value: "",
        };
        const customFields = formTemplate.values_and_traits_custom_fields;
        if (customFields) {
          const customIncludedOptions =
            scenario.order == 1
              ? customFields.values_included_options
              : customFields.traits_included_options;
          for (const i in customIncludedOptions) {
            const customIncludedOption = customIncludedOptions[i];
            if (customIncludedOption.key.indexOf("custom_") !== 0) continue;
            const customQuestion = {};
            _.merge(customQuestion, defaultQuestion, {
              id: customIncludedOption.key,
              name: customIncludedOption.label,
              title: customIncludedOption.labelEN,
              title_th: customIncludedOption.labelTH,
            });
            questions.push(customQuestion);
          }
        }
      }

      const key = _.snakeCase(scenario.name);
      let targetObj = {};
      if (
        summaries.values_and_traits &&
        summaries.values_and_traits.candidate
      ) {
        targetObj = Object.prototype.hasOwnProperty.call(
          summaries.values_and_traits.candidate,
          key
        )
          ? summaries.values_and_traits.candidate[key]
          : {};
      }
      // const keys = Object.keys(summaries.values_and_traits.candidate[key]);
      const keys = Object.keys(targetObj);
      scenarios[i].questions = _.filter(questions, (question) => {
        return _.indexOf(keys, question.id) !== -1;
      });

      // make a status
      for (const j in scenarios[i].questions) {
        const question = scenarios[i].questions[j];
        const companyValue =
          summaries.values_and_traits.company[key][question.id];
        // const candidateValue =
        //   summaries.values_and_traits.candidate[key][question.id];
        // let status = "yellow";
        // if (companyValue > 3) {
        //   status = "green";
        // } else if (companyValue < 3) {
        //   status = "red";
        // }
        // console.log(question.title, "question.title");
        question.status = this.getColorOfValuesAndTraitsValue(companyValue);
      }
    }
    return scenarios;
  },
  transformOaicArchetypes(data: any, scenarios: any[], locale: string): any[] {
    const summaries = data.summaries || {};
    let diff: any = {};
    if (hasOwnProperty(summaries, "oaic_archetypes")) {
      diff = summaries.oaic_archetypes.diff || {};
    }
    let info: any = {};
    if (hasOwnProperty(summaries, "oaic_archetypes")) {
      info = summaries.oaic_archetypes.info || {};
    }
    const items = [];

    // overall is first item
    items.push({
      value:
        summaries && summaries.oaic_archetypes
          ? summaries.oaic_archetypes.overall
          : 0,
      title: "Overall",
      title_th: "ภาพรวม",
      order: 0,
      id: "overall",
      status: this.getStatusByValue(
        summaries && summaries.oaic_archetypes
          ? summaries.oaic_archetypes.overall
          : 0
      ),
      description:
        "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
      sum_description:
        "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
      description_th:
        "ความเหลื่อมล้ำโดยรวมระหว่างวัฒนธรรมการทำงานในอุดมคติของผู้สมัครและวัฒนธรรมการทำงานขององค์กรของคุณ",
      sum_description_th:
        "ความเหลื่อมล้ำโดยรวมระหว่างวัฒนธรรมการทำงานในอุดมคติของผู้สมัครและวัฒนธรรมการทำงานขององค์กรของคุณ",
      candidate:
        summaries &&
        summaries.oaic_archetypes &&
        hasOwnProperty(summaries.oaic_archetypes, "candidate_avg")
          ? summaries.oaic_archetypes.candidate_avg
          : null,
      company:
        summaries &&
        summaries.oaic_archetypes &&
        hasOwnProperty(summaries.oaic_archetypes, "company_avg")
          ? summaries.oaic_archetypes.company_avg
          : null,
    });

    for (const i in scenarios) {
      const id = _.snakeCase(scenarios[i].name);
      const value = hasOwnProperty(diff, id) ? diff[id] : 0;
      const item = {
        value: value,
        title: scenarios[i].name,
        title_th: scenarios[i].title_th,
        order: scenarios[i].order,
        id: id,
        status: this.getStatusByValue(value),
        description: scenarios[i].description,
        admin_description: scenarios[i].admin_description,
        admin_description_th: scenarios[i].admin_description_th,
        sum_description: scenarios[i].sum_description,
        sum_description_th: scenarios[i].sum_description_th,
        tooltipOncultureLabel: {
          collaborate: _.filter(scenarios[i].questions, {
            profile: "clan",
          }).map((v) => translate(v, "admin_title", locale))[0],
          create: _.filter(scenarios[i].questions, {
            profile: "create",
          }).map((v) => translate(v, "admin_title", locale))[0],
          compete: _.filter(scenarios[i].questions, {
            profile: "complete",
          }).map((v) => translate(v, "admin_title", locale))[0],
          control: _.filter(scenarios[i].questions, {
            profile: "control",
          }).map((v) => translate(v, "admin_title", locale))[0],
        },
        candidate:
          info && info.candidate && hasOwnProperty(info.candidate, id)
            ? info.candidate[id]
            : null,
        company:
          info && info.company && hasOwnProperty(info.company, id)
            ? info.company[id]
            : null,
      };
      items.push(item);
    }
    return items;
  },
  transformAcceptableBehaviors(
    formTemplate: any,
    data: any,
    scenarios: any[],
    locale: string
  ): any[] {
    // console.log("transformAcceptableBehaviors()");

    const coloursMap: any = {};
    coloursMap[0] = "five"; // Acceptable: 0
    coloursMap[1] = "four"; // Frowned Upon: 1
    coloursMap[2] = "three"; // Actively Discouraged: 2
    coloursMap[3] = "two"; // Reprimanded: 3
    coloursMap[4] = "one"; // Zero Tolerance Policy: 4

    // const summary = data.summaries.acceptable_behaviors;

    // values map qid: value
    const companyValuesMap: any = {};
    for (const i in formTemplate.acceptable_behaviors) {
      const item = formTemplate.acceptable_behaviors[i];
      for (const qid in item) {
        companyValuesMap[qid] = item[qid];
      }
    }

    const candidateValuesMap: any = {};
    for (const i in data.acceptable_behaviors) {
      const item = data.acceptable_behaviors[i];
      for (const qid in item) {
        candidateValuesMap[qid] = item[qid];
      }
    }

    const items: any = [];
    try {
      for (const i in scenarios) {
        const scenario = scenarios[i];
        // const scenarioId = scenario.name;
        for (const j in scenario.questions) {
          const question = scenario.questions[j];
          const options = question.options;
          // const companyValue = summary.company[scenarioId][question.id];
          const companyValue = companyValuesMap[question.id];
          const companyOption = _.find(options, { value: companyValue });
          // const candidateValue = summary.candidate[scenarioId][question.id];
          const candidateValue = candidateValuesMap[question.id];
          const candidateOption = _.find(options, { value: candidateValue });

          // Change display of matching color
          // @todo reusable
          let matchingStatus = companyValue === candidateValue;
          let matchingPerfectStatus = matchingStatus;
          if (!matchingStatus) {
            matchingStatus =
              (companyValue == 1 || companyValue == 2) &&
              (candidateValue == 1 || candidateValue == 2);
            if (matchingStatus) {
              matchingPerfectStatus = false;
            }
          }

          const id = _.snakeCase(scenario.title) + "-" + question.id;
          const handler = {
            set: (target: any, prop: string, value: any) => {
              if (prop == "candidateValue") {
                const candidateValue = value;
                const candidateOption = _.find(options, {
                  value: candidateValue,
                });
                target.userValueLabel = candidateOption
                  ? translate(candidateOption, "label", locale).trim()
                  : "";
                target.userStatus = coloursMap[candidateValue];

                const companyValue = target.companyValue;
                let matchingStatus = companyValue === candidateValue;
                let matchingPerfectStatus = matchingStatus;
                if (!matchingStatus) {
                  matchingStatus =
                    (companyValue == 1 || companyValue == 2) &&
                    (candidateValue == 1 || candidateValue == 2);
                  if (matchingStatus) {
                    matchingPerfectStatus = false;
                  }
                }
                target.matchingStatus = matchingStatus;
                target.matchingPerfectStatus = matchingPerfectStatus;
              }
              return Reflect.set(target, prop, value);
            },
          };
          const item = new Proxy(
            {
              id: id,
              title: scenario.title,
              order: scenario.order,
              description: question.title,
              description_th: question.title_th,
              matchingStatus: matchingStatus,
              matchingPerfectStatus: matchingPerfectStatus,
              companyValueLabel: companyOption
                ? translate(companyOption, "label", locale).trim()
                : "",
              companyValueLabel_en: companyOption
                ? translate(companyOption, "label", "en").trim()
                : "",
              companyValueLabel_th: companyOption
                ? translate(companyOption, "label", "th").trim()
                : "",
              companyStatus: coloursMap[companyValue],
              userValueLabel: candidateOption
                ? translate(candidateOption, "label", locale).trim()
                : "",
              userValueLabel_en: candidateOption
                ? translate(candidateOption, "label", "en").trim()
                : "",
              userValueLabel_th: candidateOption
                ? translate(candidateOption, "label", "th").trim()
                : "",
              userStatus: coloursMap[candidateValue],
              scenarioName: _.snakeCase(scenario.name),
              questionId: question.id,
              companyValue: companyValue,
              candidateValue: candidateValue,
            },
            handler
          );
          items.push(item);
        }
      }
    } catch (e) {
      console.log(e);
      // console.trace();
    }

    return items;
  },
  transformScenarios(
    data: any,
    scenarios: any[],
    sectionName: string,
    rootState: any
  ): any[] {
    // let summaries = _.cloneDeep(data.summaries) || {};
    const locale = rootState.assessment.locale;
    const formTemplate = rootState.assessment.formTemplate;
    let newScenarios = [];
    switch (sectionName) {
      case "oaic_archetypes":
        newScenarios = this.transformOaicArchetypes(data, scenarios, locale);
        break;
      case "values_and_traits":
        newScenarios = this.transformValuesAndTraits(
          formTemplate,
          data,
          scenarios
        );
        break;
      case "acceptable_behaviors":
        newScenarios = this.transformAcceptableBehaviors(
          formTemplate,
          data,
          scenarios,
          locale
        );
        break;
    }
    return newScenarios;
  },
};

let unsubscribeSummaries: any = null;

export default {
  namespaced: true,
  state: {
    error: null,
    loading: false,
    ignoreLoading: false,
    invitation: null,
    candidate: {
      email: "",
      firstname: "",
      lastname: "",
    },
    candidates: [],
    data: null,
    formTemplate: null,
    summaries: null,
    creator: {
      companyName: "",
    },
    pagination: null,
    locale: "en",
    unselectedQuestions: null,
    humanSkillsResult: null,
    doNotUpdateAcScenarios: false,
    clientAcScenarios: null,
  },
  mutations: {
    doNotUpdateAcScenarios(
      state: { doNotUpdateAcScenarios: boolean },
      doNotUpdateAcScenarios: boolean
    ): any {
      state.doNotUpdateAcScenarios = doNotUpdateAcScenarios;
    },
    clientAcScenarios(
      state: { clientAcScenarios: any },
      clientAcScenarios: { id?: any }
    ): any {
      state.clientAcScenarios = clientAcScenarios;
    },
    error(state: { error: boolean }, error: boolean): any {
      state.error = error;
    },
    loading(
      state: { loading: boolean; ignoreLoading: boolean },
      loading: boolean
    ): any {
      if (state.ignoreLoading) return;
      state.loading = loading;
    },
    ignoreLoading(
      state: { ignoreLoading: boolean },
      ignoreLoading: boolean
    ): any {
      state.ignoreLoading = ignoreLoading;
    },
    candidate(state: { candidate: Candidate }, candidate: Candidate): any {
      state.candidate = candidate;
    },
    invitation(
      state: { invitation: InviteSendEmailWeb },
      invitation: InviteSendEmailWeb
    ): any {
      state.invitation = invitation;
    },
    formTemplate(
      state: { formTemplate: FormTemplate },
      formTemplate: FormTemplate
    ): any {
      state.formTemplate = formTemplate;
    },
    candidates(state: { candidates: any }, candidates: { _id: string }): any {
      state.candidates = candidates;
    },
    data(state: { data: Assessment }, data: Assessment): any {
      state.data = data;
    },
    summaries(
      state: { summaries: { oaic_archetypes?: any } },
      summaries: { oaic_archetypes?: any }
    ): any {
      state.summaries = summaries;
    },
    creator(state: { creator: any }, creator: { companyName: string }): any {
      state.creator = creator;
    },
    pagination(state: { pagination: Pagination }, pagination: Pagination): any {
      state.pagination = pagination;
    },
    locale(state: { locale: string }, locale: string): any {
      state.locale = locale;
    },
    unselectedQuestions(
      state: { unselectedQuestions: any },
      unselectedQuestions: { id?: any }
    ): any {
      state.unselectedQuestions = unselectedQuestions;
    },
    analytics(
      state: { analytics: { values_and_traits?: any } },
      analytics: { values_and_traits?: any }
    ): any {
      state.analytics = analytics;
    },
    humanSkillsResult(
      state: { humanSkillsResult: { core_values: any; human_skills: any } },
      humanSkillsResult: { core_values: any; human_skills: any }
    ): any {
      state.humanSkillsResult = humanSkillsResult;
    },
  },
  getters: {
    doNotUpdateAcScenarios(state: { doNotUpdateAcScenarios: boolean }): any {
      return state.doNotUpdateAcScenarios;
    },
    clientAcScenarios(state: { clientAcScenarios: any }): any {
      return state.clientAcScenarios;
    },
    error(state: { error: boolean }): any {
      return state.error;
    },
    loading(state: { loading: boolean }): any {
      return state.loading;
    },
    candidate(state: { candidate: Candidate }): any {
      return state.candidate;
    },
    invitation(state: { invitation: InviteSendEmailWeb }): InviteSendEmailWeb {
      return state.invitation;
    },
    formTemplate(state: { formTemplate: FormTemplate }): FormTemplate {
      return state.formTemplate;
    },
    candidates(state: { candidates: any[] }): any {
      return state.candidates;
    },
    data(state: { data: Assessment }): Assessment {
      return state.data;
    },
    summaries(state: { summaries: any }): any {
      return state.summaries;
    },
    creator(state: { creator: { companyName: string } }): any {
      return state.creator;
    },
    pagination(state: { pagination: { pagination: Pagination } }): any {
      return state.pagination;
    },
    unselectedQuestions(state: { unselectedQuestions: any }): any {
      return state.unselectedQuestions;
    },
    analytics(state: { analytics: any }): any {
      return state.analytics;
    },
    humanSkillsResult(state: { humanSkillsResult: any }): any {
      return state.humanSkillsResult;
    },
  },
  actions: {
    check({ commit, rootState }: { commit: any; rootState: any }): boolean {
      const user = rootState.auth.user;
      if (!user) {
        commit(
          "auth/errorMessage",
          "You are not logged in. Please login and try again.",
          { root: true }
        );
        return false;
      }
      return true;
    },
    async loadCreator(
      { commit }: { commit: any },
      { email }: { email: string }
    ): Promise<any> {
      const emailKey = helpers.emailKey(email);
      const path = `users/${emailKey}`;
      const creator = (await get(query(ref(getDatabase(), path)))).val();
      commit("creator", creator);
    },
    // don't check invitation by public sharing
    async _loadById(
      { commit, dispatch }: { commit: any; dispatch: any },
      {
        _id,
        lang,
        sendFreeStuff,
      }: { _id: string; lang?: string; sendFreeStuff?: boolean }
    ): Promise<any> {
      const now = helpers.now();
      const id = _id.split("-")[0];

      const $db = getFirestore();
      const docRef = doc($db, "assessment_templates", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const formTemplate = docSnap.data();
        commit("formTemplate", formTemplate);

        if (formTemplate.creator) {
          dispatch("loadCreator", { email: formTemplate.creator });
        }

        const valuesAndTraitsVersionId = _.get(
          formTemplate,
          "values_and_traits_version_id",
          null
        );
        dispatch(
          "assessmentSection/setValuesAndTraitsVersionId",
          valuesAndTraitsVersionId,
          { root: true }
        );

        type keys = keyof typeof formTemplate;
        const sectionNames = [
          "values_and_traits",
          "oaic_archetypes",
          "acceptable_behaviors",
          "work_style_identifier",
          "logic_test",
          "custom_survey",
        ];
        const settingSections = formTemplate.sections || [];
        for (const index in settingSections) {
          const sectionName = settingSections[index].toLowerCase();
          if (_.indexOf(sectionNames, sectionName) === -1) continue;
          const key = sectionName as keys;
          await dispatch("assessmentSection/loadSetupByName", key, {
            root: true,
          });
        }

        // if (settingSections) return;

        // const cachedId = "assessment_sections";
        // await dispatch("caches/load", cachedId, { root: true });
        // if (rootState[_.camelCase(cachedId)]) {
        //   console.log();
        // } else {
        //   for (const index in settingSections) {
        //     const sectionName = settingSections[index].toLowerCase();
        //     if (_.indexOf(sectionNames, sectionName) === -1) continue;
        //     const key = sectionName as keys;
        //     console.time(`loadSetupByName(${key})`);
        //     await dispatch("assessmentSection/loadSetupByName", key, {
        //       root: true,
        //     });
        //     console.timeEnd(`loadSetupByName(${key})`);
        //   }
        // }

        const $docRef = doc($db, "assessments", _id);
        const $docSnap = await getDoc($docRef);
        if (!$docSnap.exists()) {
          const errorMsg = "No such assessment.";
          commit("error", errorMsg);
          commit("loading", false);
        } else {
          const original = $docSnap.data();
          // console.log(original, "original");
          let joined_at = original.joined_at || "";
          if (!joined_at) {
            joined_at = helpers.now();
          }
          const status =
            original.status == "INVITED" ? "TODO" : original.status;

          // stat dates and status
          const newValues = {
            lang: lang,
            send_free_stuff: sendFreeStuff,
            last_accessed_at: now,
            joined_at: joined_at,
            status: status,
          };
          await setDoc(doc($db, "assessments", _id), newValues, {
            merge: true,
          });

          const currentData = { ...original, ...newValues };
          commit("data", currentData);

          // update last activity date
          // @deprecated use firestore function
          // if (original.status != "COMPLETED") {
          //   dispatch(
          //     "assessmentTemplate/updateLastActivityAt",
          //     original.form_id,
          //     { root: true }
          //   );
          // }

          if (!formTemplate.is_started_test) {
            dispatch(
              "assessmentTemplate/startTest",
              { _id: id },
              { root: true }
            );
          }
        }

        commit("loading", false);
      } else {
        const errorMsg = "No such assessment template.";
        console.error(errorMsg);
        commit("error", errorMsg);
        commit("loading", false);
      }
    },
    async loadById(
      { commit, dispatch }: { commit: any; dispatch: any },
      {
        _id,
        isSecured,
        lang,
        sendFreeStuff,
      }: {
        _id: string;
        isSecured?: boolean;
        lang?: string;
        sendFreeStuff?: boolean;
      }
    ): Promise<any> {
      const db = getDatabase();
      const conlection = "invite_send_email_web";
      const path = `${conlection}/${_id}`;

      // public link
      // if (_id.endsWith("@temp||happily||ai")) {
      if (!isSecured) {
        await dispatch("_loadById", {
          _id: _id,
          lang: lang,
          sendFreeStuff: sendFreeStuff,
        });
        return;
      }

      const now = helpers.now();
      const id = _id.split("-")[0];

      // invitation data
      commit("loading", true);
      await get(child(ref(db), path))
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            const invitation = snapshot.val();
            commit("invitation", invitation);

            // @todo check actionUrl and current URL
            try {
              const actionUrl = invitation.actionUrl;
              const uuid = actionUrl.split("/").reverse()[0];
              if (helpers.isBase64(uuid)) {
                const params = new URLSearchParams(
                  decodeURIComponent(atob(String(uuid)))
                );
                commit("candidate", {
                  email: params.get("email"),
                  firstname: params.get("firstname"),
                  lastname: params.get("lastname"),
                });
              }
            } catch (e) {
              // @note temporary fix bug about invitation and shorten url
              console.log(e, "error");
            }

            const $db = getFirestore();
            const docRef = doc($db, "assessment_templates", id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              const formTemplate = docSnap.data();
              commit("formTemplate", formTemplate);

              if (formTemplate.creator) {
                dispatch("loadCreator", { email: formTemplate.creator });
              }

              const valuesAndTraitsVersionId = _.get(
                formTemplate,
                "values_and_traits_version_id",
                null
              );
              // console.log(valuesAndTraitsVersionId, "valuesAndTraitsVersionId");
              dispatch(
                "assessmentSection/setValuesAndTraitsVersionId",
                valuesAndTraitsVersionId,
                { root: true }
              );

              type keys = keyof typeof formTemplate;
              const sectionNames = [
                "values_and_traits",
                "oaic_archetypes",
                "acceptable_behaviors",
                "work_style_identifier",
                "logic_test",
                "custom_survey",
              ];
              const settingSections = formTemplate.sections || [];
              for (const index in settingSections) {
                const sectionName = settingSections[index].toLowerCase();
                if (_.indexOf(sectionNames, sectionName) === -1) continue;
                const key = sectionName as keys;
                await dispatch("assessmentSection/loadSetupByName", key, {
                  root: true,
                });
              }

              const $docRef = doc($db, "assessments", _id);
              const $docSnap = await getDoc($docRef);
              if (!$docSnap.exists()) {
                const errorMsg = "No such assessment.";
                commit("error", errorMsg);
                commit("loading", false);
              } else {
                const original = $docSnap.data();
                let joined_at = original.joined_at || "";
                if (!joined_at) {
                  joined_at = helpers.now();
                }
                const status =
                  original.status == "INVITED" ? "TODO" : original.status;

                // stat dates and status
                const newValues = {
                  lang: lang,
                  send_free_stuff: sendFreeStuff,
                  last_accessed_at: now,
                  joined_at: joined_at,
                  status: status,
                };
                await setDoc(doc($db, "assessments", _id), newValues, {
                  merge: true,
                });

                const currentData = { ...original, ...newValues };
                commit("data", currentData);

                // update last activity date
                // @deprecated use firestore function
                // if (original.status != "COMPLETED") {
                //   dispatch(
                //     "assessmentTemplate/updateLastActivityAt",
                //     original.form_id,
                //     { root: true }
                //   );
                // }

                if (!formTemplate.is_started_test) {
                  dispatch(
                    "assessmentTemplate/startTest",
                    { _id: id },
                    { root: true }
                  );
                }
              }

              commit("loading", false);
            } else {
              const errorMsg = "No such assessment template.";
              console.error(errorMsg);
              commit("error", errorMsg);
              commit("loading", false);
            }
          } else {
            const errorMsg = `Invaid form ID: ${_id}`;
            console.error(errorMsg);
            commit("error", errorMsg);
            commit("loading", false);
          }
        })
        .catch((error) => {
          console.error(error);
          commit("error", error.message);
          commit("loading", false);
        });
    },
    // @todo common function
    async getFormTemplate(
      { commit }: { commit: any },
      { _id }: { _id: string }
    ): Promise<any> {
      commit("loading", true);
      const id = _id.split("-")[0];
      const $db = getFirestore();
      const docRef = doc($db, "assessment_templates", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const formTemplate = docSnap.data();
        commit("formTemplate", formTemplate);
      } else {
        const errorMsg = "No such assessment template.";
        console.error(errorMsg);
        commit("error", errorMsg);
        commit("loading", false);
      }
      commit("loading", false);
    },
    async load(
      { commit, dispatch }: { commit: any; dispatch: any },
      {
        id,
        uuid,
        isSecured,
        lang,
        sendFreeStuff,
      }: {
        id: string;
        uuid: string;
        isSecured?: boolean;
        lang?: string;
        sendFreeStuff?: boolean;
      }
    ): Promise<any> {
      const params = new URLSearchParams(
        decodeURIComponent(atob(String(uuid)))
      );
      const email = params.get("email") || "";
      // const firstname = params.get("firstname") || "";
      // const lastname = params.get("lastname") || "";

      // @todo validate form
      const $email = email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

      if (!$email) {
        commit("error", "Invalid email address.");
      }

      // @note new feature allow empty firstname and/or lastname from inviation
      //
      // if (!$email || !firstname || !lastname) {
      //   commit("error", "Incomplete information email, firstname or lastname.");
      // }

      // @todo load from firestore and realtime database
      const formId = id;
      const _email = helpers.emailKey(email);
      const _id = `${formId}-${_email}`;

      await dispatch("loadById", {
        _id: _id,
        isSecured: isSecured,
        lang: lang,
        sendFreeStuff,
      });
      // console.timeEnd(`load(${id})`);
    },
    async calculate(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; rootState: any },
      _id: string
    ): Promise<any> {
      const $db = getFirestore();
      const $docRef = doc($db, "assessments", _id);

      commit("loading", true);
      const $docSnap = await getDoc($docRef);
      if (!$docSnap.exists()) {
        commit("error", "No such assessment: " + _id);
        commit("loading", false);
        return;
      }

      // @todo flags of calculation
      const data = $docSnap.data();
      const summaries: any = data.summaries || {};
      let isDirty = false;

      const hasValuesAndTraits = hasOwnProperty(data, "values_and_traits");
      const hasValuesAndTraitsSummary = hasOwnProperty(
        summaries,
        "values_and_traits"
      );
      const hasOaicArchetypes = hasOwnProperty(data, "oaic_archetypes");
      const hasOaicArchetypesSummary = hasOwnProperty(
        summaries,
        "oaic_archetypes"
      );
      const hasAcceptableBehaviors = hasOwnProperty(
        data,
        "acceptable_behaviors"
      );
      const hasAcceptableBehaviorsSummary = hasOwnProperty(
        summaries,
        "acceptable_behaviors"
      );

      // work_style_identifier
      const hasWorkStyleIdentifier = hasOwnProperty(
        data,
        "work_style_identifier"
      );
      const hasWorkStyleIdentifierSummary = hasOwnProperty(
        summaries,
        "work_style_identifier"
      );

      const id = _id.split("-")[0];
      if (!id) {
        commit("error", "No such assessment template: " + _id);
        commit("loading", false);
        return;
      }

      const sectionNames = [
        "values_and_traits",
        "oaic_archetypes",
        "acceptable_behaviors",
        "work_style_identifier",
        "logic_test",
        "custom_survey",
      ];

      const docRef = doc($db, "assessment_templates", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const formTemplate = docSnap.data();
        commit("formTemplate", formTemplate);

        // improve performance by load data only setting(s)
        const settingSections = formTemplate.sections || [];
        await dispatch("assessmentSection/loadSetupByNames", settingSections, {
          root: true,
        });

        type keys = keyof typeof formTemplate;
        for (const i in sectionNames) {
          const sectionName = sectionNames[i];
          const key = sectionName as keys;
          // work_style_identifier doesn't need company settings
          if (key != "work_style_identifier" && !formTemplate[key]) continue;

          const hasDataValues = Object.prototype.hasOwnProperty.call(data, key);
          if (!hasDataValues) continue;

          let doProceed = false;
          switch (key) {
            case "values_and_traits":
              doProceed = hasValuesAndTraits && !hasValuesAndTraitsSummary;
              break;
            case "oaic_archetypes":
              doProceed = hasOaicArchetypes && !hasOaicArchetypesSummary;
              break;
            case "acceptable_behaviors":
              doProceed =
                hasAcceptableBehaviors && !hasAcceptableBehaviorsSummary;
              break;
            case "work_style_identifier":
              doProceed =
                hasWorkStyleIdentifier && !hasWorkStyleIdentifierSummary;
              break;
          }

          // @fixed
          // doProceed = true;

          const $key = _.camelCase(String(key));

          if (doProceed && key == "values_and_traits") {
            const summary: any = {
              company: {},
              candidate: {},
              overall: 0,
            };
            const sectionNames = [
              "defining_your_values",
              "identifying_key_traits",
            ];
            for (const k in sectionNames) {
              const sectionName = sectionNames[k];

              const scores = Object.values(formTemplate[key][sectionName]);

              for (const questionId in formTemplate[key][sectionName]) {
                const score = formTemplate[key][sectionName][questionId];
                if (!hasOwnProperty(summary.company, sectionName)) {
                  summary.company[sectionName] = {};
                }
                summary.company[sectionName][questionId] = score;
              }
              let totalScore = 0;
              let totalQuestion = 0;
              for (const i in data[key][sectionName]) {
                const questionId = data[key][sectionName][i];
                // @todo know options value map (1:5)
                const score = formTemplate[key][sectionName][questionId];

                if (!hasOwnProperty(summary.candidate, sectionName)) {
                  summary.candidate[sectionName] = {};
                }
                summary.candidate[sectionName][questionId] = score;
                totalScore += score;
                totalQuestion++;
              }
              scores.sort();
              const _scores = scores.slice(-1 * totalQuestion);
              const maxScore = _.sum(_scores);
              const value = (totalScore / maxScore) * 100;
              summary.overall += value;
              summary[sectionName] = decimalFormat(value);
              // console.groupEnd();
            }

            summary.overall = decimalFormat(
              summary.overall / sectionNames.length
            );

            isDirty = true;
            summaries[key] = summary;
          } else if (doProceed && key == "oaic_archetypes") {
            const questionsMaps: any = {};
            for (const _key in rootState.assessmentSection[$key].scenarios) {
              const $_key = String(_.snakeCase(_key));
              questionsMaps[$_key] = {};
              const questions =
                rootState.assessmentSection[$key].scenarios[_key].questions;
              for (const i in questions) {
                const question = questions[i];
                const qid = question.id;
                questionsMaps[$_key][qid] = question;
              }
            }

            const summary: any = {
              overall: 0,
              diff: {},
              company: {},
              candidate: {},
              company_avg: {},
              candidate_avg: {},
              info: {
                company: {},
                candidate: {},
              },
              metrics: {
                company: {},
                candidate: {},
              },
            };
            for (const name in data.oaic_archetypes) {
              // console.group(name);
              let scoreAll = 0;
              for (const qid in data.oaic_archetypes[name]) {
                const question = questionsMaps[name][qid];
                const profile = question.profile;
                const companyValue = formTemplate.oaic_archetypes[name][qid];
                const candidateValue = data.oaic_archetypes[name][qid];
                const score = Math.abs(companyValue - candidateValue);
                scoreAll += score;

                if (!hasOwnProperty(summary.info.company, name)) {
                  summary.info.company[name] = {};
                }
                summary.info.company[name][profile] = companyValue;

                if (!hasOwnProperty(summary.info.candidate, name)) {
                  summary.info.candidate[name] = {};
                }
                summary.info.candidate[name][profile] = candidateValue;

                if (
                  !Object.prototype.hasOwnProperty.call(
                    summary.company,
                    profile
                  )
                ) {
                  summary.company[profile] = 0;
                }
                summary.company[profile] += companyValue;

                if (
                  !Object.prototype.hasOwnProperty.call(
                    summary.candidate,
                    profile
                  )
                ) {
                  summary.candidate[profile] = 0;
                }
                summary.candidate[profile] += candidateValue;

                if (
                  !Object.prototype.hasOwnProperty.call(
                    summary.metrics.company,
                    profile
                  )
                ) {
                  summary.metrics.company[profile] = [];
                }
                summary.metrics.company[profile].push(companyValue);

                if (
                  !Object.prototype.hasOwnProperty.call(
                    summary.metrics.candidate,
                    profile
                  )
                ) {
                  summary.metrics.candidate[profile] = [];
                }
                summary.metrics.candidate[profile].push(candidateValue);
              }

              const diffScore = (1 - (scoreAll / 400) * 2) * 100;
              summary.diff[name] = diffScore;
            }

            for (const profile in summary.company) {
              summary.company_avg[profile] = decimalFormat(
                summary.company[profile] /
                  summary.metrics.company[profile].length
              );
            }

            for (const profile in summary.candidate) {
              summary.candidate_avg[profile] = decimalFormat(
                summary.candidate[profile] /
                  summary.metrics.candidate[profile].length
              );
            }

            // Use real values
            const profiles = Object.keys(summary.metrics.candidate);
            for (const i in profiles) {
              const profile = profiles[i];
              const companyValue =
                summary.company[profile] /
                summary.metrics.company[profile].length;
              const candidateValue =
                summary.candidate[profile] /
                summary.metrics.candidate[profile].length;
              summary.overall += Math.abs(companyValue - candidateValue);
            }
            summary.overall = decimalFormat(
              (1 - (summary.overall / 400) * 2) * 100
            );

            isDirty = true;
            summaries[key] = summary;
          } else if (doProceed && key == "acceptable_behaviors") {
            const summary: any = {
              company: {},
              candidate: {},
              scenarios: {},
              overall: 0,
            };
            let totalValue = 0;
            let totalQuestion = 0;

            // Improve score calculation for new data structure
            // map questions qid: val
            const companyValuesMap: any = {};
            for (const i in formTemplate[key]) {
              const item = formTemplate[key][i];
              for (const qid in item) {
                companyValuesMap[qid] = item[qid];
              }
            }

            const candidateValuesMap: any = {};
            for (const i in data[key]) {
              const item = data[key][i];
              for (const qid in item) {
                candidateValuesMap[qid] = item[qid];
              }
            }

            for (const scenarioId in formTemplate[key]) {
              const scenario = formTemplate[key][scenarioId];
              for (const questionId in scenario) {
                // const companyValue = scenario[questionId];
                const companyValue = companyValuesMap[questionId];
                // const candidateValue = data[key][scenarioId][questionId];
                const candidateValue = candidateValuesMap[questionId];
                if (!hasOwnProperty(summary.company, scenarioId))
                  summary.company[scenarioId] = {};
                if (!hasOwnProperty(summary.candidate, scenarioId))
                  summary.candidate[scenarioId] = {};
                if (!hasOwnProperty(summary.scenarios, scenarioId))
                  summary.scenarios[scenarioId] = {};

                const value = companyValue === candidateValue ? 1 : 0;
                totalValue += value;
                totalQuestion++;
                summary.company[scenarioId][questionId] = companyValue;
                summary.candidate[scenarioId][questionId] = candidateValue;
                summary.scenarios[scenarioId][questionId] = value;
              }
            }
            if (totalQuestion > 0) {
              summary.overall = (totalValue / totalQuestion) * 100;
            }
            isDirty = true;
            summaries[key] = summary;
          } else if (doProceed && key == "work_style_identifier") {
            // console.log("compute: work_style_identifier");
            // find possibilities of all questions
            // assessmentSection/workStyleIdentifier

            // making questions map for calculate any stat
            const questionsMap: any = {};
            const possibilities: any = {};
            const scores: any = {};
            const hasOwnProperty = Object.prototype.hasOwnProperty;
            for (const _key in rootState.assessmentSection[$key].scenarios) {
              const scenario =
                rootState.assessmentSection[$key].scenarios[_key];
              const questions = scenario.questions;
              for (const i in questions) {
                questionsMap[questions[i].id] = {};
                const exists: any = {};
                for (const j in questions[i].options) {
                  const option = questions[i].options[j];
                  const { code, group, value } = option;
                  questionsMap[questions[i].id][code] = option;
                  if (!hasOwnProperty.call(possibilities, group)) {
                    possibilities[group] = 0;
                  }
                  // console.log(_key, questions[i].id, group, code, value);
                  const $value = Number(value);
                  possibilities[group] += $value;
                  if (hasOwnProperty.call(exists, group)) {
                    if ($value > exists[group]) {
                      possibilities[group] -= exists[group];
                    } else {
                      possibilities[group] -= $value;
                    }
                  }
                  exists[group] = value;
                }
              }
            }

            // console.log(questionsMap, "questionsMap");
            // console.log(possibilities, "possibilities");

            for (const scenarioId in data[key]) {
              for (const questionId in data[key][scenarioId]) {
                const optionCode = data[key][scenarioId][questionId];
                const option = questionsMap[questionId][optionCode];
                const group = option.group;
                const value = Number(option.value);
                if (!hasOwnProperty.call(scores, group)) {
                  scores[group] = 0;
                }
                // console.log(
                //   `${scenarioId}, ${questionId} -> ${group}: ${value}`
                // );
                scores[group] += value;
              }
            }

            // @see https://docs.google.com/spreadsheets/d/10A_WjQigd_8r-5RHca1fKqZBFntlMpa7TdCH-p0_C7s/edit?pli=1#gid=1618802810
            const firstSteps: any = {};
            const scoreTotal = _.sum(Object.values(scores));
            const overall: any = {};
            for (const group in possibilities) {
              const firstStep = scores[group] / possibilities[group];
              firstSteps[group] = firstStep;
              overall[group] = firstStep / (scoreTotal / possibilities[group]);
            }

            // console.log(scores, "scores");
            // console.log(firstSteps, "firstSteps");
            // console.log(scoreTotal, "scoreTotal");
            // console.log(overall, "overall");
            isDirty = true;
            summaries[key] = {
              scores: scores,
              possible: possibilities,
              first_steps: firstSteps,
              overall: overall,
            };
            // console.log(summaries, "summaries");
          }
        }
      } else {
        commit("No such assessment: " + _id);
        commit("loading", false);
        return;
      }

      const length = Object.keys(summaries).length;
      let sectionTotal = length;
      // console.log(sectionTotal, "sectionTotal");
      // console.log(isDirty, "isDirty");
      // console.log(_id, "_id");
      if (isDirty && length > 0) {
        // summaries.overall = 0;
        let overall = 0;
        for (const x in summaries) {
          // console.log(x, "x", "overall", summaries[x].overall);
          if (x == "work_style_identifier" || x == "overall") {
            if (sectionTotal > 1) sectionTotal--;
            continue;
          }
          if (_.indexOf(sectionNames, x) === -1) continue;
          // summaries.overall += summaries[x].overall;
          // summaries.overall += Math.round(summaries[x].overall);
          overall += Math.round(summaries[x].overall);
        }
        summaries.overall = overall;
        summaries.overall = decimalFormat(summaries.overall / sectionTotal);

        data.summaries = summaries;
        const $docRef = doc($db, "assessments", _id);
        const $docSnap = await getDoc($docRef);
        if (!$docSnap.exists()) {
          await setDoc($docRef, { summaries: summaries }, { merge: true });
        }
      }

      commit("data", data);
      commit("loading", false);
      return summaries;
    },
    async getSummaries(
      {
        commit,
        dispatch,
        getters,
        rootState,
      }: { commit: any; dispatch: any; getters: any; rootState: any },
      { _id, doNotClearData }: { _id: string; doNotClearData?: boolean }
    ): Promise<any> {
      // console.log(`getSummaries(${_id})`);
      if (!_id) return;

      commit("loading", true);
      commit("ignoreLoading", true);

      if (!doNotClearData) {
        commit("data", null);
      }

      const $db = getFirestore();
      const $docRef = doc($db, "assessments", _id);
      const $docSnap = await getDoc($docRef);
      if (!$docSnap.exists()) {
        commit("error", "No such assessment: " + _id);
        commit("loading", false);
        commit("ignoreLoading", false);
        return;
      }

      let formTemplate;
      if (!rootState.assessment.formTemplate) {
        const formId = _id.split("-")[0];
        const docRef = doc($db, "assessment_templates", formId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          formTemplate = docSnap.data();
          commit("formTemplate", formTemplate);
        }
      }

      if (!formTemplate && rootState.assessment.formTemplate) {
        formTemplate = rootState.assessment.formTemplate;
      }
      let assessment = $docSnap.data();
      // @see https://trello.com/c/matDYoYk/1651-report-as-a-hiring-manager-i-want-to-see-time-spent-on-each-section-so-that-i-can-take-time-into-consideration-when-studying-res
      const extraTime = (formTemplate.extra_time || 0) * 60;
      const sectionSpentTimes: any = {
        values_and_traits: 5 * 60 + extraTime,
        OAIC_archetypes: 10 * 60 + extraTime,
        human_skills: 10 * 60 + extraTime,
        logic_test: 10 * 60 + extraTime,
        acceptable_behaviors: 5 * 60 + extraTime,
        work_style_identifier: 5 * 60 + extraTime,
        custom_survey: 10 * 60 + extraTime,
      };
      const sectionTimes = assessment.section_times || {};
      for (const sectionName in sectionTimes) {
        if (
          Object.prototype.hasOwnProperty.call(sectionSpentTimes, sectionName)
        ) {
          const remainingTime = sectionTimes[sectionName] || 0;
          if (remainingTime === 0) {
            sectionSpentTimes[sectionName] = 0;
          } else {
            sectionSpentTimes[sectionName] -= remainingTime;
          }
        }
      }

      assessment.section_spent_times = _.pick(
        sectionSpentTimes,
        Object.keys(sectionTimes)
      );
      // console.log('section_spent_times ', assessment.section_spent_times );
      // send data for skeleton loading
      // commit("data", assessment);

      if (!assessment.summaries) {
        await dispatch("calculate", _id);
        assessment = rootState.assessment.data;
        commit("data", assessment);
      }

      // Custom fields
      const customQuestions: any = {};
      if (formTemplate.values_and_traits_is_custom) {
        const defaultQuestion = {
          name: "",
          option: [
            { value: 1, label: "1" },
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "5" },
          ],
          order: 9999,
          title: "",
          title_th: "",
          type: "choice ",
        };
        const customFields = formTemplate.values_and_traits_custom_fields;
        if (customFields) {
          const valuesIncludedOptions = customFields.values_included_options;
          const traitsIncludedOptions = customFields.traits_included_options;
          const allCustomQuestions = _.concat(
            valuesIncludedOptions,
            traitsIncludedOptions
          );
          for (const i in allCustomQuestions) {
            const customQuestion = allCustomQuestions[i];
            const key = customQuestion.key;
            if (key.indexOf("custom_") === 0) {
              const newQuestion = {};
              _.merge(newQuestion, defaultQuestion, {
                name: customQuestion.label,
                title: customQuestion.labelEN,
                title_th: customQuestion.labelTH,
              });
              customQuestions[key] = newQuestion;
            }
          }
        }
      }

      const data = assessment;
      const summaries = data.summaries || {};
      const sectionNames = [
        "values_and_traits",
        "oaic_archetypes",
        "acceptable_behaviors",
        "logic_test",
        "custom_survey",
      ];
      for (const i in sectionNames) {
        const sectionName = sectionNames[i];
        if (hasOwnProperty(data, sectionName)) {
          if (
            sectionName == "values_and_traits" ||
            sectionName == "oaic_archetypes" ||
            sectionName == "acceptable_behaviors" ||
            sectionName == "logic_test" ||
            sectionName == "custom_survey"
          ) {
            // const debug = sectionName == "values_and_traits";
            const debug = false;

            if (sectionName == "values_and_traits") {
              const valuesAndTraitsVersionId = _.get(
                formTemplate,
                "values_and_traits_version_id",
                ""
              );
              dispatch(
                "assessmentSection/setValuesAndTraitsVersionId",
                valuesAndTraitsVersionId,
                { root: true }
              );
            }

            const varName = _.camelCase(sectionName);
            debug && console.log("!value");
            await dispatch("assessmentSection/loadSetupByName", sectionName, {
              root: true,
            });
            const value = rootState.assessmentSection[varName];
            if (value && value.scenarios) {
              let scenarios = [];
              for (const i in value.scenarios) {
                const scenario = value.scenarios[i];
                scenarios.push(scenario);
              }

              if (sectionName == "acceptable_behaviors") {
                // console.log(JSON.stringify(value.scenarios), "json");
                if (formTemplate.acceptable_behaviors_is_custom) {
                  const customFields =
                    formTemplate.acceptable_behaviors_custom_fields;
                  // console.log(customFields, "customFields");
                  const defaultQuestion = {
                    checked: false,
                    dirty: false,
                    id: "",
                    name: "",
                    options: [
                      { label: "Acceptable", value: 0, label_th: "ยอมรับได้" },
                      {
                        label: " Frowned Upon",
                        label_th: "ไม่พอใจ/ไม่ยอมรับ",
                        value: 1,
                      },
                      {
                        label_th: "แสดงออกว่าไม่สนับสนุน",
                        label: "Actively Discouraged",
                        value: 2,
                      },
                      {
                        label: "Reprimanded",
                        value: 3,
                        label_th: "ต้องมีการตำหนิหรือลงโทษ",
                      },
                      {
                        value: 4,
                        label_th: "ยอมรับไม่ได้อย่างเด็ดขาด",
                        label: " Zero Tolerance Policy",
                      },
                    ],
                    order: 1,
                    title: "",
                    title_th: "",
                    type: "choice",
                    value: "",
                  };
                  // const newScenarios = [];
                  const newScenarios: any = {};
                  const uniqueKeys: any = {};
                  for (const i in customFields) {
                    // console.log(i, "i");
                    const uiQuestion = customFields[i];
                    const targetQuestion = { id: 1, order: 1 };
                    _.merge(targetQuestion, defaultQuestion, {
                      id: uiQuestion.id,
                      name: uiQuestion.label,
                      order: uiQuestion.index,
                      title: _.has(uiQuestion, "labelEN")
                        ? uiQuestion.labelEN
                        : uiQuestion.label,
                      title_th: uiQuestion.labelTH,
                    });
                    const scenario = {
                      description:
                        '<div class="">Please provide a rating for each scenario based on your preference and tolerance levels.</div>',
                      description_th:
                        '<div class="">' +
                        helpers.t(
                          "question.acceptableBehaviors.Please_provide_a_rating_for_each"
                        ) +
                        "</div>",
                      id: targetQuestion.id,
                      name: "scenario_" + targetQuestion.order,
                      order: targetQuestion.order,
                      questions: [targetQuestion],
                      title: "Evaluating Acceptable Behaviors",
                      title_th: helpers.t(
                        "question.acceptableBehaviors.Evaluating_Acceptable_Behaviors"
                      ),
                    };
                    // newScenarios.push(scenario);
                    // console.log(scenario.name, "scenario.name");
                    if (!_.has(uniqueKeys, targetQuestion.order)) {
                      uniqueKeys[targetQuestion.order] = 1;
                    }
                    let key = scenario.name;
                    if (_.has(newScenarios, key)) {
                      uniqueKeys[targetQuestion.order]++;
                      // @todo fix this bug in the future for duplicated key
                      // key = "scenario_" + targetQuestion.order + "_" + uniqueKeys[targetQuestion.order];
                      key = "scenario_" + targetQuestion.order;
                    }
                    newScenarios[key] = scenario;
                  }
                  scenarios = newScenarios;
                }
              }

              scenarios = _.orderBy(scenarios, ["order"], "asc");
              scenarios = uiAssembler.transformScenarios(
                data,
                scenarios,
                sectionName,
                rootState
              );
              if (summaries[sectionName]) {
                if (sectionName == "acceptable_behaviors") {
                  if (!getters.doNotUpdateAcScenarios) {
                    summaries[sectionName].scenarios = scenarios;
                  } else {
                    summaries[sectionName].scenarios =
                      getters.clientAcScenarios;
                  }
                } else {
                  summaries[sectionName].scenarios = scenarios;
                }
              }
            }
          }
        }
      }

      // Important - 5
      if (hasOwnProperty(data, "values_and_traits")) {
        const TARGET_VALUE = 5;
        const settings = _.get(summaries, "values_and_traits.company") || {};
        const allQuestions = rootState.assessmentSection.allQuestions || {};
        const unselectedQuestions: any = {
          defining_your_values: [],
          identifying_key_traits: [],
        };

        for (const key in settings) {
          let targetObj = {};
          if (summaries.values_and_traits.candidate) {
            targetObj = Object.prototype.hasOwnProperty.call(
              summaries.values_and_traits.candidate,
              key
            )
              ? summaries.values_and_traits.candidate[key]
              : {};
          }
          // const candidateSelectedIds = Object.keys(
          //   summaries.values_and_traits.candidate[key]
          // );
          const candidateSelectedIds = Object.keys(targetObj);
          for (const qid in settings[key]) {
            const isSelected = _.indexOf(candidateSelectedIds, qid) !== -1;
            if (isSelected) continue;
            const value = settings[key][qid];
            if (value == TARGET_VALUE) {
              // console.log(`key: ${key}, quid: ${qid}, value: ${value}`);
              let question = allQuestions[qid];
              if (!question) {
                question = customQuestions[qid];
              }
              if (question) {
                unselectedQuestions[key].push(question);
              } else {
                console.log(`Question: ${qid} was not found.`);
              }
            }
          }
          unselectedQuestions[key] = _.sortBy(
            unselectedQuestions[key],
            "title"
          );
        }
        commit("unselectedQuestions", unselectedQuestions);
      }

      if (
        _.has(data, "logic_test") &&
        _.has(rootState.assessmentSection, "logicTest")
      ) {
        const logic_test = _.get(data, "logic_test");
        const logicTest = _.get(rootState.assessmentSection, "logicTest");
        const scenarios = _.get(logicTest, "scenarios", {});
        const map: any = {};
        for (const k in scenarios) {
          const scenario = scenarios[k];
          const questions = _.get(scenario, "questions", {});
          _.sortBy(questions, ["order"]);
          _.set(map, `section_${scenario.order}`, []);
          for (const q in questions) {
            const question = questions[q];
            const qid = question.id;
            map[`section_${scenario.order}`].push(qid);
          }
        }
        for (const k in logic_test) {
          const pairs = logic_test[k];
          const newPairs: any = {};
          const qids = _.get(map, k, []);
          for (const n in qids) {
            const qid = qids[n];
            newPairs[qid] = pairs[qid];
          }
          logic_test[k] = newPairs;
        }
      }

      // ready for load scenarios
      commit("data", assessment);
      commit("summaries", summaries);
      commit("ignoreLoading", false);
      commit("loading", false);
      return summaries;
    },
    async getPagination(
      { commit }: { commit: any },
      { _id }: { _id: string }
    ): Promise<any> {
      commit("ignoreLoading", true);

      const formId = _id.split("-")[0];

      const $db = getFirestore();
      const docRef = doc($db, "assessment_templates", formId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const formTemplate = docSnap.data();
        const children = formTemplate.children || [];
        const index = _.indexOf(children, _id);
        if (index !== -1) {
          const pageTotal = children.length;
          const currentPage = index + 1;
          const previous = index - 1;
          const next = index + 1;
          const hasPrevious = previous >= 0 && previous < pageTotal;
          const hasNext = next >= 0 && next < pageTotal;
          const pagination = {
            pageTotal: pageTotal,
            currentPage: currentPage,
            hasPrevious: hasPrevious,
            hasNext: hasNext,
            previous: null,
            next: null,
          };
          // @see src\views\Respondents.vue
          const makeRouteLocationRaw = (_id: string): any => {
            const as_id = _id.split("-")[0];
            const u_id = btoa(encodeURIComponent(_id));
            const routeLocationRaw = {
              name: "AssessmentsCandidate",
              query: {
                as_id: as_id,
                u_id: u_id,
              },
            };
            return routeLocationRaw;
          };

          if (hasPrevious)
            pagination.previous = makeRouteLocationRaw(children[previous]);
          if (hasNext) pagination.next = makeRouteLocationRaw(children[next]);

          commit("pagination", pagination);
        }
      } else {
        commit("error", "Not found form ID: " + formId);
      }
      commit("ignoreLoading", false);
    },
    async createFromInvitation(
      { commit }: { commit: any },
      inviteWeb: {
        email: string;
        formId: string;
        firstname: string;
        lastname: string;
        creator: string;
        companyName: string;
        publicUrl: string;
        createdAt: string;
      }
    ): Promise<any> {
      const now = helpers.now();
      const _email = helpers.filterPath(helpers.filterEmail(inviteWeb.email));
      const _id = `${inviteWeb.formId}-${_email}`;

      const $db = getFirestore();
      const docRef = doc($db, "assessment_templates", inviteWeb.formId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const formTemplate = docSnap.data();
        const formDetails = {
          email: inviteWeb.email,
          form_id: inviteWeb.formId,
          form_name: formTemplate.form_name,
          firstname: inviteWeb.firstname,
          lastname: inviteWeb.lastname,
          creator: inviteWeb.creator,
          company_name: String(inviteWeb.companyName),
          action_url: inviteWeb.publicUrl,
          status: "INVITED",
          invited_at: inviteWeb.createdAt,
          last_accessed_at: now,
          created_at: now,
          updated_at: now,
          deleted: false,
        };
        await setDoc(doc($db, "assessments", _id), formDetails);
        commit("error", "");
      } else {
        commit("error", "Not found form id: " + inviteWeb.formId);
      }
    },
    async start(
      { commit }: { commit: any },
      formDetails: {
        _id: string;
      }
    ): Promise<any> {
      commit("loading", true);
      const _id = formDetails._id;

      const $db = getFirestore();
      const docRef = doc($db, "assessments", _id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.status == "TODO") {
          const now = helpers.now();
          const updatedFormDetails = {
            status: "IN_PROGRESS",
            is_started_test: true,
            started_test_at: now,
          };
          await setDoc(doc($db, "assessments", _id), updatedFormDetails, {
            merge: true,
          });
        }
      } else {
        const errorMsg = "No such assessment.";
        commit("error", errorMsg);
      }
      commit("loading", false);
    },
    async saveFormDetails(
      { commit, dispatch, state }: { commit: any; dispatch: any; state: any },
      formDetails: { values_and_traits?: any; status?: string }
    ): Promise<any> {
      // @todo validate _id that ailas to realtime database
      const formId = state.invitation.formId;
      const email = state.invitation.email;
      const _email = helpers.filterPath(helpers.filterEmail(email));
      const _id = `${formId}-${_email}`;

      formDetails = _.merge(formDetails, { updated_at: helpers.now() });

      // console.log(_id, "_id");
      // console.log(formDetails, "formDetails");

      commit("loading", true);
      const $db = getFirestore();
      const docRef = doc($db, "assessments", _id);
      await setDoc(docRef, formDetails, {
        merge: true,
      });

      //@fixed test update quota on completed
      if (formDetails.status == "COMPLETED") {
        const docSnap = await getDoc(doc($db, "assessments", _id));
        await dispatch(
          "quota/processQuotaSummariesAfterCompleted",
          { doc: docSnap },
          { root: true }
        );
      }

      // @deprecated use firestore function
      // dispatch("assessmentTemplate/updateLastActivityAt", formId, {
      //   root: true,
      // });

      commit("loading", false);
    },
    async updateAcceptableBehavior(
      { commit }: { commit: any },
      formDetails: { _id: string; acceptable_behaviors: any }
    ): Promise<any> {
      // console.log("updateAcceptableBehavior()");
      // console.log(formDetails._id, "_id");
      commit("ignoreLoading", true);
      commit("loading", true);
      // @todo ACL Who can edit this
      // console.log(formDetails, "formDetails");
      const _id = formDetails._id;

      // clear previous object data
      /*
      for (const i in formDetails.acceptable_behaviors) {
        const o: any = {};
        console.log();
        o[i] = null;
        const data = {
          acceptable_behaviors: o,
        };
        console.log(data, "data");
        const $db = getFirestore();
        const docRef = doc($db, "assessments", _id);
        await setDoc(docRef, data, {
          merge: true,
        });
      }
      //*/
      const $db = getFirestore();
      let data;
      let docRef;

      // clear field without merge object of acceptable_behaviors
      data = {
        status: "RECOMPUTE",
        acceptable_behaviors: deleteField(),
        summaries: {
          acceptable_behaviors: deleteField(),
        },
      };
      docRef = doc($db, "assessments", _id);
      await setDoc(docRef, data, {
        merge: true,
      });

      // recompute again
      data = {
        status: "RECOMPUTE",
        acceptable_behaviors: formDetails.acceptable_behaviors,
      };
      docRef = doc($db, "assessments", _id);
      await setDoc(docRef, data, {
        merge: true,
      });

      await setDoc(
        docRef,
        { status: "COMPLETED" },
        {
          merge: true,
        }
      );
      commit("loading", false);
      commit("ignoreLoading", false);
    },
    async subscribeSummaries(
      { commit, dispatch }: { commit: any; dispatch: any },
      { _id, form_id, email }: { _id: string; form_id: string; email: string }
    ): Promise<any> {
      const db = getFirestore();
      const path = "assessments";
      const q = $query(
        collection(db, path),
        where("form_id", "==", form_id),
        where("email", "==", email)
      );
      const callback = () => {
        commit("ignoreLoading", true);
        dispatch("getSummaries", { _id, doNotClearData: true });
        commit("ignoreLoading", false);
      };
      unsubscribeSummaries = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.doc.id != _id) return;
          if (change.type == "modified") {
            const data = change.doc.data();
            if (!data) return;
            if (data.status != "COMPLETED") return;
            if (_.has(data, "summaries.acceptable_behaviors")) {
              callback();
            }
          }
        });
      });
    },
    unsubscribeSummaries(): any {
      if (_.isFunction(unsubscribeSummaries)) {
        unsubscribeSummaries();
        unsubscribeSummaries = null;
      }
    },
    async finish(
      {
        dispatch,
        state,
        rootState,
      }: { dispatch: any; state: any; rootState: any },
      _formDetails: {
        firstname?: string;
        lastname?: string;
        values_and_traits?: any;
      }
    ): Promise<any> {
      const now = helpers.now();
      if (typeof _formDetails != "object") _formDetails = {};
      const formDetails = _.merge(_formDetails, {
        finished_at: now,
        status: "COMPLETED",
      });

      // console.log(formValues, "formValues");
      // const routeData = rootState.routes.data;
      // const isSecured =
      //   routeData && routeData.path == "/assessment-test/:id/:params";
      const candidate = rootState.assessment.candidate;
      // console.log(isSecured, "isSecured");
      if (candidate) {
        formDetails.firstname = candidate.firstname;
        formDetails.lastname = candidate.lastname;
      }

      await dispatch("saveFormDetails", formDetails);

      const formId = state.invitation.formId;
      if (formId) {
        dispatch("assessmentTemplate/updateProgress", formId, {
          root: true,
        });
      }
    },
    async getCandidates(
      { commit, dispatch }: { commit: any; dispatch: any },
      { formId, page }: { formId: string; page: number }
    ): Promise<any> {
      if (!dispatch("check")) return;

      commit("loading", true);
      const $db = getFirestore();
      const q = $query(
        collection($db, "assessments"),
        where("form_id", "==", formId),
        orderBy("created_at", "desc")
      );

      if (!page) page = 1;

      const querySnapshot = await getDocs(q);
      let items: any[] = [];
      querySnapshot.forEach(async (doc: any) => {
        const data = doc.data();
        const summaries = data.summaries ? data.summaries.overall : "0";
        const firstname = String(data.firstname);
        const lastname = String(data.lastname);
        const joined_at = data.joined_at || "";
        const display_name = firstname
          ? `${firstname} ${lastname}`.trim()
          : data.email;
        const defaults = {
          display_name: display_name,
          rating: 0,
          score: summaries,
          joined_at: joined_at,
        };

        items.push({ _id: doc.id, ...defaults, ...data });
      });

      items = _.sortBy(items, "score").reverse();

      const docRef = doc($db, "assessment_templates", formId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const formTemplate = docSnap.data();
        commit("formTemplate", formTemplate);
      }
      items = _.sortBy(items, "score").reverse();
      commit("candidates", items);
      commit("loading", false);
    },
    async deleteCandidate(
      { commit, dispatch }: { commit: any; dispatch: any },
      candidate: { form_id: string; email: string }
    ): Promise<any> {
      commit("loading", true);
      const { form_id: formId, email } = candidate;

      // realtime database: inviteweb, invite_send_email_web
      // firestore: assessments
      const db = getDatabase();

      // realtime database: inviteweb
      let path = `inviteweb`;
      let _routeId = "";
      const snapshots = await get(
        query(ref(db, path), orderByChild("email"), equalTo(email))
      );
      snapshots.forEach((child) => {
        if (child.exists()) {
          const value = child.val();
          if (value.formId == formId) {
            set(ref(db, `inviteweb/${child.key}`), null)
              .then(() => {
                _routeId = value.routeId;
                //console.log(path);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        }
      });

      // realtime database: invite_send_email_web
      const emailKey = helpers.filterPath(helpers.filterEmail(email));
      const formUniqueId = `${formId}-${emailKey}`;
      path = `invite_send_email_web/${formUniqueId}`;
      get(child(ref(db), path))
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            // console.log(`remove ${path} from invite_send_email_web`);
            set(ref(db, path), null)
              .then(() => {
                //console.log(path);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        })
        .catch((error) => {
          console.error(error);
        });

      // firestore: assessments
      const $db = getFirestore();
      const $docRef = doc($db, "assessments", formUniqueId);
      const $docSnap = await getDoc($docRef);
      if ($docSnap.exists()) {
        // console.log(`remove ${path} from assessments`);
        await deleteDoc(doc($db, "assessments", formUniqueId));
        if (_routeId) {
          await deleteDoc(doc($db, "routes", _routeId));
        }
      }

      dispatch("assessmentTemplate/updateProgress", formId, {
        root: true,
      });
      commit("loading", false);
    },
    async saveRating(
      { commit }: { commit: any },
      assementDetails: { _id: string; rating: number }
    ): Promise<any> {
      commit("loading", true);
      const _id = assementDetails._id;
      let rating = assementDetails.rating;
      const fs = getFirestore();

      const docRef = doc(fs, "assessments", _id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        // doesn't change
        if (data.rating == rating) {
          assementDetails.rating = 0;
          rating = assementDetails.rating;
        }
        await setDoc(
          doc(fs, "assessments", _id),
          { rating: rating },
          {
            merge: true,
          }
        );
      }

      commit("loading", false);
    },
    async sentResultToCandidateEmail(
      { commit }: { commit: any },
      { form_id, email }: { form_id: string; email: string }
    ): Promise<any> {
      commit("loading", true);
      const _id = `${form_id}-${helpers.emailKey(email)}`;
      const now = helpers.now();
      const formDetails = {
        is_sent_result: true,
        sent_result_at: now,
        updated_at: now,
      };
      const $db = getFirestore();
      await setDoc(doc($db, "assessments", _id), formDetails, {
        merge: true,
      });
      commit("loading", false);
    },
    async sectionStates(
      {
        commit,
        rootState,
      }: {
        commit: any;
        rootState: any;
      },
      FormDetailData: FormDetailData
    ): Promise<any> {
      commit("loading", false);
      const user = rootState.auth.user;
      // console.log(user);
      const _id = FormDetailData.code;
      const _stateAcceptable = FormDetailData.statusSectionAccep
        ? FormDetailData.statusSectionAccep
        : false;
      const _stateOAICA = FormDetailData.statusSectionOAICA
        ? FormDetailData.statusSectionOAICA
        : false;
      const _stateValue = FormDetailData.statusSectionValue
        ? FormDetailData.statusSectionValue
        : false;
      const _stateWork = FormDetailData.statusSectionWork
        ? FormDetailData.statusSectionWork
        : false;
      const _stateLogicTest = FormDetailData.statusSectionLogic
        ? FormDetailData.statusSectionLogic
        : false;
      const _stateCustomSurvey = FormDetailData.statusSectionSurvey
        ? FormDetailData.statusSectionSurvey
        : false;
      const db = getFirestore();
      const docRef = doc(db, "routes", _id);
      const docSnap = await getDoc(docRef);
      let formid = "";
      let emailcandidate = "";
      if (docSnap.exists()) {
        const routeData = docSnap.data();
        if (routeData.data.publicUrl) {
          formid = routeData.data.formId;
          emailcandidate = routeData.data.email;
        } else {
          formid = routeData.uuid;
          emailcandidate = user.email;
        }
      }
      const _email = helpers.emailKey(emailcandidate);
      const id_candidate = `${formid}-${_email}`;
      const docRef1 = doc(db, "assessments", id_candidate);
      const $docSnap = await getDoc(docRef1);
      if ($docSnap.exists()) {
        if (_stateWork) {
          await setDoc(
            doc(db, "assessments", id_candidate),
            {
              section_states: {
                work_style_identifier: _stateWork,
              },
            },
            {
              merge: true,
            }
          );
        } else if (_stateAcceptable) {
          await setDoc(
            doc(db, "assessments", id_candidate),
            {
              section_states: {
                acceptable_behaviors: _stateAcceptable,
              },
            },
            {
              merge: true,
            }
          );
        } else if (_stateValue) {
          await setDoc(
            doc(db, "assessments", id_candidate),
            {
              section_states: {
                values_and_traits: _stateValue,
              },
            },
            {
              merge: true,
            }
          );
        } else if (_stateOAICA) {
          await setDoc(
            doc(db, "assessments", id_candidate),
            {
              section_states: {
                OAIC_archetypes: _stateOAICA,
              },
            },
            {
              merge: true,
            }
          );
        } else if (_stateLogicTest) {
          await setDoc(
            doc(db, "assessments", id_candidate),
            {
              section_states: {
                logic_test: _stateLogicTest,
              },
            },
            {
              merge: true,
            }
          );
        } else if (_stateCustomSurvey) {
          await setDoc(
            doc(db, "assessments", id_candidate),
            {
              section_states: {
                custom_survey: _stateCustomSurvey,
              },
            },
            {
              merge: true,
            }
          );
        }
      }
    },
    // @todo use firebase function to compute this (cloud function)
    // collection: analytics
    async getAnalytics(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; rootState: any },
      { _id, locale }: { _id: string; locale: string }
    ): Promise<any> {
      // @todo may be get it first, lazy load or background process
      if (!_id) {
        console.log("Undefined _id....");
        return;
      }

      commit("loading", true);
      const analytics: any = {
        values_and_traits: {
          defining_your_values: {},
        },
        oaic_archetypes: {
          criteria_of_success: [],
          dominant_characteristics: [],
          management_of_employees: [],
          organization_glue: [],
          organizational_leadership: [],
          strategic_emphases: [],
        },
        human_skills: {
          critical_thinking: [],
          empathy: [],
          situational_awareness: [],
          written_communication: [],
        },
        logic_test: [],
        acceptable_behaviors: {},
        custom_survey: [],
      };
      const candidates = _.filter(rootState.assessment.candidates || [], {
        status: "COMPLETED",
      });
      // console.log(candidates, "candidates");
      if (!candidates.length) {
        console.log("No Candidate completed the assessment...");
        commit("loading", false);
        return;
      }

      if (!rootState.assessment.formTemplate) {
        console.log("No formTemplate data...");
        commit("loading", false);
        return;
      }

      // load sections
      const formTemplate = rootState.assessment.formTemplate;
      const sectionNames = _.map(formTemplate.sections || [], (str) =>
        str.toLowerCase()
      );
      // console.log(sectionNames, "sectionNames");
      for (const i in sectionNames) {
        const sectionName = sectionNames[i].toLowerCase();
        await dispatch("assessmentSection/loadSetupByName", sectionName, {
          root: true,
        });
        // const varName = _.camelCase(sectionName);
        // const value = rootState.assessmentSection[varName];
      }

      // creator for finding companyName
      const emailKey = helpers.emailKey(formTemplate.creator);
      const path = `users/${emailKey}`;
      const creator = (await get(query(ref(getDatabase(), path)))).val();

      const allQuestions = rootState.assessmentSection.allQuestions || false;

      // Because we have custom fields for each forms such as
      // values and traits or acceptable behaviros
      // console.log(formTemplate, "formTemplate");
      if (formTemplate.values_and_traits_is_custom) {
        if (_.isObject(formTemplate.values_and_traits_custom_fields)) {
          for (const n of [
            "values_included_options",
            "traits_included_options",
          ]) {
            const options = _.get(
              formTemplate,
              "values_and_traits_custom_fields." + n
            );
            for (const i in options) {
              const option = options[i];
              if (option) {
                const key = option.key;
                if (!Object.prototype.hasOwnProperty.call(allQuestions, key)) {
                  allQuestions[key] = {
                    type: "choice",
                    name: option.label,
                    order: i,
                    title: option.labelEN,
                    title_th: option.labelTH,
                    options: [
                      {
                        value: 1,
                        label: "1",
                      },
                      {
                        value: 2,
                        label: "2",
                      },
                      {
                        value: 3,
                        label: "3",
                      },
                      {
                        value: 4,
                        label: "4",
                      },
                      {
                        value: 5,
                        label: "5",
                      },
                    ],
                  };
                }
              }
            }
          }
        }
      }
      if (formTemplate.acceptable_behaviors_is_custom) {
        if (_.isObject(formTemplate.acceptable_behaviors_custom_fields)) {
          const options = formTemplate.acceptable_behaviors_custom_fields;
          for (const i in options) {
            const option = options[i];
            if (option) {
              const key = option.id;
              if (!Object.prototype.hasOwnProperty.call(allQuestions, key)) {
                allQuestions[key] = {
                  type: "choice",
                  name: option.label,
                  order: option.index,
                  title: option.labelEN,
                  title_th: option.labelTH,
                  options: [
                    { label: "Acceptable", label_th: "ยอมรับได้", value: 0 },
                    {
                      label: "Frowned Upon",
                      value: 1,
                      label_th: "ไม่พอใจ/ไม่ยอมรับ",
                    },
                    {
                      value: 2,
                      label_th: "แสดงออกว่าไม่สนับสนุน",
                      label: " Actively Discouraged",
                    },
                    {
                      value: 3,
                      label: " Reprimanded",
                      label_th: "ต้องมีการตำหนิหรือลงโทษ",
                    },
                    {
                      label_th: "ยอมรับไม่ได้อย่างเด็ดขาด",
                      value: 4,
                      label: "Zero Tolerance Policy",
                    },
                  ],
                };
              }
            }
          }
        }
      }

      if (!allQuestions) {
        console.log("No allQuestions map...");
        commit("loading", false);
        return;
      }

      // console.log(allQuestions, "allQuestions");
      const candidateValuesMap: any = {};
      const candidateTraitsMap: any = {};
      const importantPrefix = "important_";

      // console.log(formTemplate, "formTemplate");

      // accpetable behaviors
      if (
        hasOwnProperty(formTemplate, "acceptable_behaviors_is_custom") &&
        hasOwnProperty(formTemplate, "acceptable_behaviors_custom_fields")
      ) {
        let order = 0;
        for (const i in formTemplate.acceptable_behaviors_custom_fields) {
          const key = formTemplate.acceptable_behaviors_custom_fields[i].id;
          analytics.acceptable_behaviors[key] = {
            name: "",
            question: _.get(
              formTemplate.acceptable_behaviors_custom_fields[i],
              locale == "th" ? "labelTH" : "labelEN"
            ),
            order: ++order,
            valuePercentage: 0,
            candidateValue: [],
          };
        }
      } else {
        const acPatth = "assessmentSection.acceptableBehaviors.scenarios";
        let acScenarios = _.get(rootState, acPatth) || [];
        acScenarios = _.sortBy(acScenarios, "order");
        // console.log(acScenarios, "acScenarios");
        let order = 0;
        for (const i in acScenarios) {
          // const key = acScenarios[i].name;
          // const key = i;
          const key = _.get(acScenarios[i], "questions[0].id");
          analytics.acceptable_behaviors[key] = {
            name: "",
            question: _.get(acScenarios[i], "questions[0].title"),
            order: ++order,
            valuePercentage: 0,
            candidateValue: [],
          };
        }
      }

      // @todo one method all variables (summaries)
      // console.log(candidates, "candidates");
      const users: any = [];
      const isV2 = Object.prototype.hasOwnProperty.call(
        formTemplate,
        "values_and_traits_v_2"
      );
      _.each(candidates, (candidate: any) => {
        const displayName = candidate.display_name;
        const user: any = {
          name: displayName,
          displayName: displayName,
          shortName:
            String(candidate.firstname)[0].toUpperCase() +
            String(candidate.lastname)[0].toUpperCase(),
          id: candidate._id,
          color: "",
        };
        users.push(user);
        for (const i in sectionNames) {
          const sectionName = sectionNames[i];
          if (
            candidate.summaries &&
            hasOwnProperty(candidate.summaries, sectionName)
          ) {
            if (sectionName == "values_and_traits") {
              const summary = candidate.summaries[sectionName];
              const importantValuesMap: any = {};
              const importantTraitsMap: any = {};
              const companySettings = summary.company || {};
              const candidateSettings = summary.candidate || {};

              // create important mapping
              for (const key in companySettings) {
                if (key == "defining_your_values") {
                  for (const qid in companySettings[key]) {
                    const value = companySettings[key][qid];
                    const name = importantPrefix + value;
                    if (!hasOwnProperty(importantValuesMap, name)) {
                      importantValuesMap[name] = [];
                    }
                    if (!hasOwnProperty(candidateValuesMap, name)) {
                      candidateValuesMap[name] = {};
                    }
                    importantValuesMap[name].push(qid);
                  }
                } else if (key == "identifying_key_traits") {
                  for (const qid in companySettings[key]) {
                    const value = companySettings[key][qid];
                    const name = importantPrefix + value;
                    if (!hasOwnProperty(importantTraitsMap, name)) {
                      importantTraitsMap[name] = [];
                    }
                    if (!hasOwnProperty(candidateTraitsMap, name)) {
                      candidateTraitsMap[name] = {};
                    }
                    importantTraitsMap[name].push(qid);
                  }
                }
              }

              // find candidate mapping company
              for (const key in candidateSettings) {
                if (key == "defining_your_values") {
                  for (const qid in candidateSettings[key]) {
                    const value = candidateSettings[key][qid];
                    const name = importantPrefix + value;
                    if (hasOwnProperty(importantValuesMap, name)) {
                      const isMatched =
                        _.indexOf(importantValuesMap[name], qid) !== -1;
                      if (isMatched) {
                        if (!hasOwnProperty(candidateValuesMap[name], qid))
                          candidateValuesMap[name][qid] = [];
                        let color =
                          uiAssembler.getUserColorOfValuesAndTraitsValue(value);
                        const score = summary.defining_your_values || 0;

                        // @fixed color for v2 and compatible with v1
                        if (isV2) {
                          if (value == 4) color = "five";
                          if (value == 2 || value == 3) color = "four"; // compatible with 2nd value decision
                          if (value == 0) color = "one";
                        }
                        user.defining_your_values = score;
                        candidateValuesMap[name][qid].push({
                          ...user,
                          color: color,
                        });
                      }
                    }
                  }
                } else if (key == "identifying_key_traits") {
                  for (const qid in candidateSettings[key]) {
                    const value = candidateSettings[key][qid];
                    const name = importantPrefix + value;
                    if (hasOwnProperty(importantTraitsMap, name)) {
                      const isMatched =
                        _.indexOf(importantTraitsMap[name], qid) !== -1;
                      if (isMatched) {
                        if (!hasOwnProperty(candidateTraitsMap[name], qid))
                          candidateTraitsMap[name][qid] = [];
                        const color =
                          uiAssembler.getUserColorOfValuesAndTraitsValue(value);
                        const score = summary.identifying_key_traits || 0;
                        user.identifying_key_traits = score;
                        candidateTraitsMap[name][qid].push({
                          ...user,
                          color: color,
                        });
                      }
                    }
                  }
                }
              }
            } else if (sectionName == "oaic_archetypes") {
              const summary = candidate.summaries[sectionName];
              const info = summary.info;
              const respondent = info.candidate;

              // clan, create, complete and control
              const dimensions = Object.keys(analytics.oaic_archetypes);
              for (const i in dimensions) {
                const dimension = dimensions[i];
                let dataset = [];

                // always add company first
                if (!analytics.oaic_archetypes[dimension].length) {
                  const company = info.company;
                  dataset = [
                    company[dimension].clan, // N
                    company[dimension].create, // E
                    company[dimension].complete, // S
                    company[dimension].control, // W
                  ];
                  analytics.oaic_archetypes[dimension].push({
                    user: {
                      id: "company_" + dimension,
                      name: creator.companyName,
                      displayName: creator.companyName,
                    },
                    dataset: dataset,
                    score: 9999,
                  });
                }

                dataset = [
                  respondent[dimension].clan,
                  respondent[dimension].create,
                  respondent[dimension].complete,
                  respondent[dimension].control,
                ];

                analytics.oaic_archetypes[dimension].push({
                  user: user,
                  dataset: dataset,
                  score: Math.round(summary.diff[dimension]),
                });
              }
              console.log(analytics.oaic_archetypes);
            } else if (sectionName == "human_skills") {
              const summary = candidate.summaries[sectionName];

              // written_communication, situational_awareness, critical_thinking and empathy
              const dimensions = Object.keys(analytics.human_skills);
              for (const i in dimensions) {
                const dimension = dimensions[i];
                let dataset = [];

                // always add company first
                /*
                if (!analytics.human_skills[dimension].length) {
                  dataset = [
                    100, // N: written_communication
                    100, // E: situational_awareness
                    100, // S: critical_thinking
                    100, // W: empathy
                  ];
                  analytics.human_skills[dimension].push({
                    user: {
                      id: "company_" + dimension,
                      name: creator.companyName,
                      displayName: creator.companyName,
                    },
                    dataset: dataset,
                    score: 9999,
                  });
                }
                //*/

                dataset = [
                  summary.human_skills["written_communication"].score,
                  summary.human_skills["situational_awareness"].score,
                  summary.human_skills["critical_thinking"].score,
                  summary.human_skills["empathy"].score,
                ];
                analytics.human_skills[dimension].push({
                  user: user,
                  dataset: dataset,
                  score: Math.round(summary.human_skills[dimension].score),
                });
              }
            } else if (sectionName == "logic_test") {
              const summary = candidate.summaries[sectionName].candidate_avg;
              const dataset = [];
              const dataKeys = [
                "pattern_recognition",
                "logical_reasoning",
                "data_interpretation",
                "problem_resolving",
              ];
              for (const dataKey of dataKeys) {
                dataset.push(summary[dataKey]);
              }

              if (!analytics.logic_test.length) {
                analytics.logic_test.push({
                  user: {
                    id: "company",
                    name: creator.companyName,
                    displayName: creator.companyName,
                  },
                  dataset: [0, 0, 0, 0],
                  score: 9999,
                });
              }
              analytics.logic_test.push({
                user: user,
                dataset: dataset,
                score: Math.round(sum(dataset)),
              });
              console.log("logic_test", analytics.logic_test);
            } else if (sectionName == "acceptable_behaviors") {
              const summary =
                _.get(candidate, "summaries." + sectionName) || {};
              // console.log(summary, "summary");

              // @todo global finding
              const companyValueLabels = [
                {
                  value: 0,
                  label: helpers.t("question.acceptableBehaviors.Acceptable"),
                },
                {
                  value: 1,
                  label: helpers.t("question.acceptableBehaviors.Frowned_Upon"),
                },
                {
                  label: helpers.t(
                    "question.acceptableBehaviors.Actively_Discouraged"
                  ),
                  value: 2,
                },
                {
                  label: helpers.t("question.acceptableBehaviors.Reprimanded"),
                  value: 3,
                },
                {
                  value: 4,
                  label: helpers.t(
                    "question.acceptableBehaviors.Zero_Tolerance_Policy"
                  ),
                },
              ];
              const coloursMap: any = {};
              coloursMap[0] = "one"; // Acceptable: 0
              coloursMap[1] = "two"; // Frowned Upon: 1
              coloursMap[2] = "three"; // Actively Discouraged: 2
              coloursMap[3] = "four"; // Reprimanded: 3
              coloursMap[4] = "five"; // Zero Tolerance Policy: 4

              if (summary.candidate) {
                // console.log(analytics.acceptable_behaviors, "analytics.acceptable_behaviors");
                // console.log(summary.candidate, "summary.candidate");
                for (const name in summary.candidate) {
                  const candidateData = summary.candidate[name];
                  const qid = Object.keys(candidateData)[0];
                  const candidateValue = Object.values(candidateData)[0];
                  const companyValue = _.get(
                    summary,
                    "company." + name + "." + qid
                  );

                  if (companyValue !== undefined) {
                    analytics.acceptable_behaviors[qid].companyValue =
                      companyValue;
                    const option = _.find(companyValueLabels, {
                      value: Number(companyValue),
                    });
                    if (option) {
                      analytics.acceptable_behaviors[qid].companyValueLabel =
                        option.label;
                      analytics.acceptable_behaviors[qid].companyValueColor =
                        coloursMap[option.value];
                    }
                  }

                  // Change display of matching color
                  // @todo reusable
                  let matchingStatus = companyValue === candidateValue;
                  let matchingPerfectStatus = matchingStatus;
                  if (!matchingStatus) {
                    matchingStatus =
                      (companyValue == 1 || companyValue == 2) &&
                      (candidateValue == 1 || candidateValue == 2);
                    if (matchingStatus) {
                      matchingPerfectStatus = false;
                    }
                  }
                  const _value = matchingStatus ? 1 : 3;
                  const value = matchingPerfectStatus ? 2 : _value;

                  analytics.acceptable_behaviors[qid].candidateValue.push({
                    displayName: user.displayName,
                    value: String(value),
                    score: summary.overall ? summary.overall : 0,
                  });
                }
              }
            }
          }
        }
      });

      // @see src\views\Assessments\mock_data_modal_value_and_traits.ts
      if (_.indexOf(sectionNames, "values_and_traits") !== -1) {
        // defining_your_values
        // mode: show all importants
        for (let value = 1; value <= 5; value++) {
          const name = importantPrefix + value;
          if (!hasOwnProperty(candidateValuesMap, name)) {
            candidateValuesMap[name] = {};
          }
        }
        let metrics: any = [];
        let importantTitles: any = {};
        importantTitles[importantPrefix + "5"] = "Most Important - 5";
        importantTitles[importantPrefix + "4"] = "Important - 4";
        importantTitles[importantPrefix + "3"] = "Neutral - 3";
        importantTitles[importantPrefix + "2"] = "Low Importance - 2";
        importantTitles[importantPrefix + "1"] = "Not At All Important - 1";
        // console.log(candidateValuesMap, "candidateValuesMap");
        for (const key in candidateValuesMap) {
          const priority = parseInt(key.replace(importantPrefix, ""));
          const name = hasOwnProperty(importantTitles, key)
            ? importantTitles[key]
            : "Untitled";
          const values: any = [];
          const questionsMap = candidateValuesMap[key];
          for (const qid in questionsMap) {
            const question = allQuestions[qid];
            if (!question) {
              console.error("Unknow question " + qid);
              continue;
            }

            const candidates = _.sortBy(questionsMap[qid], "displayName");
            const candidateTotal = candidates.length;

            // @todo displayed candidates may be have 2 algorithm
            // 1: can be hole (may be)
            // 2: can't be hole (using this)
            /*
            const candidate = candidates;
            const $users = _.sortBy(users, "displayName");
            for (const i in $users) {
              const user = $users[i];
              const found = _.find(candidates, { id: user.id });
              if (!found) {
                console.log(user.defining_your_values, user.displayName);
                candidate.push(user);
              }
            }
            //*/

            // Let's sort columns where left-most column is the person with the highest value fit score.
            const $users = _.sortBy(users, "defining_your_values").reverse();
            const candidate = [];
            for (const i in $users) {
              let user = { ...$users[i] };
              const found = _.find(candidates, { id: user.id });
              if (found) {
                user = { ...user, ...found };
              }
              candidate.push(user);
            }

            const percentage = users.length
              ? Math.round((candidateTotal / users.length) * 100)
              : 0;

            const value = {
              name: question.title,
              label_en: question.title,
              id: qid,
              percentage: percentage,
              candidate: candidate,
            };
            values.push(value);
          }

          const data = {
            name: name,
            label_en: name,
            id: _.snakeCase(name),
            percentage: 0,
            // value: _.sortBy(values, "label_en"),
            // And sort rows by values that have the highest match percentages.
            value: _.sortBy(values, "percentage").reverse(),
            priority: priority,
          };
          metrics.push(data);
        }
        metrics = _.sortBy(metrics, "priority").reverse();
        analytics.values_and_traits.defining_your_values = metrics;

        // identifying_key_traits
        // mode: show all importants
        for (let value = 1; value <= 5; value++) {
          const name = importantPrefix + value;
          if (!hasOwnProperty(candidateTraitsMap, name)) {
            candidateTraitsMap[name] = {};
          }
        }

        metrics = [];
        importantTitles = {};
        importantTitles[importantPrefix + "5"] = "Most Important - 5";
        importantTitles[importantPrefix + "4"] = "Important - 4";
        importantTitles[importantPrefix + "3"] = "Neutral - 3";
        importantTitles[importantPrefix + "2"] = "Low Importance - 2";
        importantTitles[importantPrefix + "1"] = "Not At All Important - 1";
        for (const key in candidateTraitsMap) {
          const priority = parseInt(key.replace(importantPrefix, ""));
          const name = hasOwnProperty(importantTitles, key)
            ? importantTitles[key]
            : "Untitled";
          const values: any = [];
          const questionsMap = candidateTraitsMap[key];
          for (const qid in questionsMap) {
            const question = allQuestions[qid];
            if (!question) {
              console.error("Unknow question " + qid);
              continue;
            }

            const candidates = _.sortBy(questionsMap[qid], "displayName");
            const candidateTotal = candidates.length;

            // Let's sort columns where left-most column is the person with the highest value fit score.
            const $users = _.sortBy(users, "defining_your_values").reverse();
            const candidate = [];
            for (const i in $users) {
              let user = { ...$users[i] };
              const found = _.find(candidates, { id: user.id });
              if (found) {
                user = { ...user, ...found };
              }
              candidate.push(user);
            }

            const percentage = users.length
              ? Math.round((candidateTotal / users.length) * 100)
              : 0;

            const value = {
              name: question.title,
              label_en: question.title,
              id: qid,
              percentage: percentage,
              candidate: candidate,
            };
            values.push(value);
          }

          const data = {
            name: name,
            label_en: name,
            id: _.snakeCase(name),
            percentage: 0,
            // value: _.sortBy(values, "label_en"),
            // And sort rows by values that have the highest match percentages.
            value: _.sortBy(values, "percentage").reverse(),
            priority: priority,
          };
          metrics.push(data);
        }
        metrics = _.sortBy(metrics, "priority").reverse();
        analytics.values_and_traits.identifying_key_traits = metrics;
      }
      if (_.indexOf(sectionNames, "oaic_archetypes") !== -1) {
        const dimensions = Object.keys(analytics.oaic_archetypes);
        for (const i in dimensions) {
          const dimension = dimensions[i];
          analytics.oaic_archetypes[dimension] = _.sortBy(
            analytics.oaic_archetypes[dimension],
            ["score"]
          ).reverse();
        }
      }
      if (_.indexOf(sectionNames, "acceptable_behaviors") !== -1) {
        if (
          !_.isArray(analytics.acceptable_behaviors) &&
          _.isObject(analytics.acceptable_behaviors)
        ) {
          analytics.acceptable_behaviors = Object.values(
            analytics.acceptable_behaviors
          );
          let items = [];
          for (const i in analytics.acceptable_behaviors) {
            const item = analytics.acceptable_behaviors[i];
            if (item.disabled === true) continue;
            let sum = 0;
            for (const j in item.candidateValue) {
              const value = item.candidateValue[j].value;
              const score = value == "3" ? 0 : 1;
              sum += score;
            }
            item.valuePercentage = item.candidateValue.length
              ? Math.round((sum / item.candidateValue.length) * 100)
              : 0;

            item.candidateValue = _.sortBy(
              item.candidateValue,
              "score"
            ).reverse();

            items.push(item);
          }
          // items = _.sortBy(items, "valuePercentage").reverse();
          items = _.sortBy(items, "valuePercentage");
          analytics.acceptable_behaviors = items;
        }
      }

      commit("analytics", analytics);
      commit("loading", false);
    },
    async saveSectionTime(
      { commit }: { commit: any },
      FormSectionTimes: FormSectionTimes
    ): Promise<any> {
      commit("loading", false);
      const _id = FormSectionTimes.code;
      const _stateAcceptable = FormSectionTimes.timeSectionAccep;
      const _stateOAICA = FormSectionTimes.timeSectionOAICA;
      const _stateValue = FormSectionTimes.timeSectionValue;
      const _stateWork = FormSectionTimes.timeSectionWork;
      const _stateLogicTest = FormSectionTimes.timeSectionLogicTest;
      const _stateHumanSkills = FormSectionTimes.timeSectionHumanSkills;
      const _stateCustomSurvey = FormSectionTimes.timeSectionSurvey;
      //timeSectionSurvey
      const db = getFirestore();
      const docRef = doc(db, "routes", _id);
      const docSnap = await getDoc(docRef);
      let formid = "";
      let emailcandidate = "";
      if (docSnap.exists()) {
        const routeData = docSnap.data();
        if (routeData.data.publicUrl) {
          formid = routeData.data.formId;
          emailcandidate = routeData.data.email;
        } else {
          const candidate = store.getters["assessment/candidate"];
          formid = routeData.uuid;
          emailcandidate = candidate.email;
        }
      }
      // console.log("_stateWork", _stateWork);
      // console.log("_stateLogicTest", _stateLogicTest);
      const _email = helpers.emailKey(emailcandidate);
      const id_candidate = `${formid}-${_email}`;
      const docRef1 = doc(db, "assessments", id_candidate);
      const $docSnap = await getDoc(docRef1);
      if ($docSnap.exists()) {
        if (_stateWork || _stateWork == 0) {
          await setDoc(
            doc(db, "assessments", id_candidate),
            {
              section_times: {
                work_style_identifier: _stateWork,
              },
            },
            {
              merge: true,
            }
          );
        } else if (_stateAcceptable || _stateAcceptable == 0) {
          await setDoc(
            doc(db, "assessments", id_candidate),
            {
              section_times: {
                acceptable_behaviors: _stateAcceptable,
              },
            },
            {
              merge: true,
            }
          );
        } else if (_stateValue || _stateValue == 0) {
          await setDoc(
            doc(db, "assessments", id_candidate),
            {
              section_times: {
                values_and_traits: _stateValue,
              },
            },
            {
              merge: true,
            }
          );
        } else if (_stateOAICA || _stateOAICA == 0) {
          await setDoc(
            doc(db, "assessments", id_candidate),
            {
              section_times: {
                OAIC_archetypes: _stateOAICA,
              },
            },
            {
              merge: true,
            }
          );
        } else if (_stateHumanSkills || _stateHumanSkills == 0) {
          await setDoc(
            doc(db, "assessments", id_candidate),
            {
              section_times: {
                human_skills: _stateHumanSkills,
              },
            },
            {
              merge: true,
            }
          );
        } else if (_stateLogicTest || _stateLogicTest == 0) {
          await setDoc(
            doc(db, "assessments", id_candidate),
            {
              section_times: {
                logic_test: _stateLogicTest,
              },
            },
            {
              merge: true,
            }
          );
        } else if (_stateCustomSurvey || _stateCustomSurvey == 0) {
          await setDoc(
            doc(db, "assessments", id_candidate),
            {
              section_times: {
                custom_survey: _stateCustomSurvey,
              },
            },
            {
              merge: true,
            }
          );
        }
      }
    },
    async resetCandidate(
      { commit }: { commit: any },
      formDetails: { assessmentId: string }
    ): Promise<any> {
      commit("loading", false);
      const db = getFirestore();
      const now = helpers.now();
      await setDoc(
        doc(db, "assessments", formDetails.assessmentId),
        {
          section_times: deleteField(),
          section_states: deleteField(),
          values_and_traits: deleteField(),
          oaic_archetypes: deleteField(),
          logic_test: deleteField(),
          acceptable_behaviors: deleteField(),
          custom_survey: deleteField(),
          status: "INVITED",
          is_started_test: false,
          updated_at: now,
        },
        {
          merge: true,
        }
      );
    },
    async calculateHumanSkillsScore(
      { commit }: { commit: any },
      formDetails: { response_1: string; response_2: string }
    ): Promise<any> {
      // console.log("calculateHumanSkillsScore()");
      // console.log(formDetails, "formDetails");

      const content = _.template(PROMPT_ROLE_USER_HUMAN_SKILLS)({
        response_1: formDetails.response_1,
        response_2: formDetails.response_2,
      });

      const params = {
        messages: [
          { role: "system", content: PROMPT_ROLE_SYSTEM },
          { role: "user", content: content },
        ],
      };

      let responseText;
      let result;
      let error;
      try {
        responseText = await request(params);
        result = JSON.parse(responseText);
        error = false;
      } catch (e) {
        error = true;
        console.error(e);
        commit("error", e);
      }

      console.log(params, "params");
      if (!error && result) {
        commit("humanSkillsResult", result);
      }
    },
  },
};
