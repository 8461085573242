import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/image/logo_myculture.svg'
import _imports_1 from '@/assets/icons/down.svg'
import _imports_2 from '@/assets/icons/ic_my_core_values.svg'
import _imports_3 from '@/assets/icons/ic_job_post.svg'
import _imports_4 from '@/assets/icons/ic_interview_guide.svg'
import _imports_5 from '@/assets/icons/ic_calendar.svg'
import _imports_6 from '@/assets/icons/ic_team_role.svg'
import _imports_7 from '@/assets/icons/ic_career_path.svg'
import _imports_8 from '@/assets/icons/ic_okrs.svg'
import _imports_9 from '@/assets/icons/ic_pip.svg'
import _imports_10 from '@/assets/icons/upload_24px.png'


const _hoisted_1 = { class: "menu-container flex-center" }
const _hoisted_2 = { class: "body-width-reponsive-max flex-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "mx-auto flex relative"
}
const _hoisted_5 = { class: "drop-down-menu" }
const _hoisted_6 = { class: "menu-dropdown label flex-center" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "desc" }
const _hoisted_9 = { class: "divider" }
const _hoisted_10 = { class: "menu-dropdown label flex-center" }
const _hoisted_11 = { class: "title" }
const _hoisted_12 = { class: "desc" }
const _hoisted_13 = { class: "menu-dropdown label flex-center" }
const _hoisted_14 = { class: "title" }
const _hoisted_15 = { class: "desc" }
const _hoisted_16 = { class: "divider" }
const _hoisted_17 = { class: "menu-dropdown label flex-center" }
const _hoisted_18 = { class: "title" }
const _hoisted_19 = { class: "desc" }
const _hoisted_20 = { class: "menu-dropdown label flex-center" }
const _hoisted_21 = { class: "title" }
const _hoisted_22 = { class: "desc" }
const _hoisted_23 = { class: "divider" }
const _hoisted_24 = { class: "menu-dropdown label flex-center" }
const _hoisted_25 = { class: "title" }
const _hoisted_26 = { class: "desc" }
const _hoisted_27 = { class: "menu-dropdown label flex-center" }
const _hoisted_28 = { class: "title" }
const _hoisted_29 = { class: "desc" }
const _hoisted_30 = { class: "menu-dropdown label flex-center" }
const _hoisted_31 = { class: "title" }
const _hoisted_32 = { class: "desc" }
const _hoisted_33 = {
  key: 3,
  class: "drop-down-menu acccount"
}
const _hoisted_34 = { class: "el-dropdown-link" }
const _hoisted_35 = { class: "label flex-center" }
const _hoisted_36 = {
  class: "max-m-none right",
  style: {"line-height":"2rem"}
}
const _hoisted_37 = { key: 0 }
const _hoisted_38 = { key: 1 }
const _hoisted_39 = { class: "fc-primary link" }
const _hoisted_40 = { key: 2 }
const _hoisted_41 = { class: "fc-primary link red" }
const _hoisted_42 = { class: "label menu-dropdown my-account flex-center" }
const _hoisted_43 = { class: "label menu-dropdown my-account flex-center" }
const _hoisted_44 = { class: "label menu-dropdown my-account flex-center" }
const _hoisted_45 = { class: "label menu-dropdown my-account flex-center" }
const _hoisted_46 = { class: "remove-header remove-padding-modal" }
const _hoisted_47 = { class: "pb-42 pl-32 pr-24 fc-1B1C1E" }
const _hoisted_48 = { class: "fs-18 fw-700" }
const _hoisted_49 = {
  class: "mt-15 fw-400 mb-38",
  style: {"word-break":"normal"}
}
const _hoisted_50 = ["placeholder"]
const _hoisted_51 = { class: "label" }
const _hoisted_52 = { class: "uploadfile-container relative mt-24" }
const _hoisted_53 = ["src"]
const _hoisted_54 = {
  key: 1,
  class: "absolute-mid text-hover center"
}
const _hoisted_55 = { class: "fs-14 fw-700 opa-06 mt-22" }
const _hoisted_56 = { class: "fs-12 opa-06 mt-8" }
const _hoisted_57 = { class: "text-underline" }
const _hoisted_58 = { class: "el-dialog-footer flex-center mt-32" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.companyLogoUrl)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "company-logo-image-container max-m-none",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/'))),
              style: {"width":"24.2rem","cursor":"pointer"}
            }, [
              _createElementVNode("img", {
                src: _ctx.companyLogoUrl,
                style: {"width":"18rem"},
                alt: "",
                class: "company-logo-image image"
              }, null, 8, _hoisted_3)
            ]))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/'))),
              style: {"width":"24.2rem","cursor":"pointer"}
            }, _cache[12] || (_cache[12] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "Happily logo",
                class: "company-logo-image",
                style: {"height":"3.2rem"}
              }, null, -1)
            ]))),
        (_ctx.$route.meta.navbarName !== 'disable')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", {
                class: _normalizeClass(["active-menu-bar", `${_ctx.menuActiveStyleBar()}`]),
                style: _normalizeStyle(`background-color: ${_ctx.companyColor}`)
              }, null, 6),
              false
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClickMyCoreValues && _ctx.onClickMyCoreValues(...args))),
                    class: _normalizeClass(`menu ${
            _ctx.menuActiveStyleBar() == 'menu-1' ? 'fc-primary' : 'fc-color'
          }`)
                  }, _toDisplayString(_ctx.$t("assessments.My_Core_Values")), 3))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({ name: 'Assessments' }))),
                class: _normalizeClass(`menu ${
            _ctx.menuActiveStyleBar() == 'menu-2' ? 'fc-primary' : 'fc-color'
          }`)
              }, _toDisplayString(_ctx.$t("assessments.My_assessments")), 3),
              _createElementVNode("div", {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push({ name: 'Respondents' }))),
                class: _normalizeClass(`menu ${
            _ctx.menuActiveStyleBar() == 'menu-3' ? 'fc-primary' : 'fc-color'
          }`)
              }, _toDisplayString(_ctx.$t("assessments.My_respondents")), 3),
              _createElementVNode("div", {
                class: _normalizeClass(`menu ${
            _ctx.menuActiveStyleBar() == 'menu-4' ? 'fc-primary' : 'fc-color'
          }`)
              }, [
                _createElementVNode("div", _hoisted_5, [
                  (_ctx.user)
                    ? (_openBlock(), _createBlock(_component_el_dropdown, {
                        key: 0,
                        onCommand: _ctx.handleCommand
                      }, {
                        dropdown: _withCtx(() => [
                          _createVNode(_component_el_dropdown_menu, null, {
                            default: _withCtx(() => [
                              (_ctx.user)
                                ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                    key: 0,
                                    command: "my_core_values"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_6, [
                                        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "mr-20 flex-center" }, [
                                          _createElementVNode("img", {
                                            src: _imports_2,
                                            class: "icon-32",
                                            alt: "My Core Values"
                                          })
                                        ], -1)),
                                        _createElementVNode("div", null, [
                                          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("core_values_analyzer.title")), 1),
                                          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("core_values_analyzer.short_desc")), 1)
                                        ])
                                      ])
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("toolbox.hiring")), 1),
                              (_ctx.user)
                                ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                    key: 1,
                                    command: "job_post_generator"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_10, [
                                        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "mr-20 flex-center" }, [
                                          _createElementVNode("img", {
                                            src: _imports_3,
                                            class: "icon-32",
                                            alt: "Job Role"
                                          })
                                        ], -1)),
                                        _createElementVNode("div", null, [
                                          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("job_post_generator.Job_post_generator")), 1),
                                          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("job_post_generator.short_desc")), 1)
                                        ])
                                      ])
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              (_ctx.user)
                                ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                    key: 2,
                                    command: "interview_guide"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_13, [
                                        _cache[15] || (_cache[15] = _createElementVNode("div", { class: "mr-20 flex-center" }, [
                                          _createElementVNode("img", {
                                            src: _imports_4,
                                            class: "icon-32",
                                            alt: "Interview Guide"
                                          })
                                        ], -1)),
                                        _createElementVNode("div", null, [
                                          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("interview_guide.Interview_guide")), 1),
                                          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("interview_guide.short_desc")), 1)
                                        ])
                                      ])
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("toolbox.onboarding")), 1),
                              (_ctx.user)
                                ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                    key: 3,
                                    command: "num_day_plan"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_17, [
                                        _cache[16] || (_cache[16] = _createElementVNode("div", { class: "mr-20 flex-center" }, [
                                          _createElementVNode("img", {
                                            src: _imports_5,
                                            class: "icon-32",
                                            alt: "OKR Generator"
                                          })
                                        ], -1)),
                                        _createElementVNode("div", null, [
                                          _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t("num_day_plan.title")), 1),
                                          _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("num_day_plan.short_desc")), 1)
                                        ])
                                      ])
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              (_ctx.user)
                                ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                    key: 4,
                                    command: "job_role_generator"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_20, [
                                        _cache[17] || (_cache[17] = _createElementVNode("div", { class: "mr-20 flex-center" }, [
                                          _createElementVNode("img", {
                                            src: _imports_6,
                                            class: "icon-32",
                                            alt: "Job Role"
                                          })
                                        ], -1)),
                                        _createElementVNode("div", null, [
                                          _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("job_role_generator.Job_role_generator")), 1),
                                          _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t("job_role_generator.short_desc")), 1)
                                        ])
                                      ])
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t("toolbox.people_management")), 1),
                              (_ctx.user)
                                ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                    key: 5,
                                    command: "career_path_tracker"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_24, [
                                        _cache[18] || (_cache[18] = _createElementVNode("div", { class: "mr-20 flex-center" }, [
                                          _createElementVNode("img", {
                                            src: _imports_7,
                                            class: "icon-32",
                                            alt: "Job Role"
                                          })
                                        ], -1)),
                                        _createElementVNode("div", null, [
                                          _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t("career_path_tracker.Career_path_tracker")), 1),
                                          _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t("career_path_tracker.short_desc")), 1)
                                        ])
                                      ])
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              (_ctx.user)
                                ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                    key: 6,
                                    command: "okr_generator"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_27, [
                                        _cache[19] || (_cache[19] = _createElementVNode("div", { class: "mr-20 flex-center" }, [
                                          _createElementVNode("img", {
                                            src: _imports_8,
                                            class: "icon-32",
                                            alt: "OKR Generator"
                                          })
                                        ], -1)),
                                        _createElementVNode("div", null, [
                                          _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t("okr_generator.Okr_generator")), 1),
                                          _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t("okr_generator.short_desc")), 1)
                                        ])
                                      ])
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              (_ctx.user)
                                ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                    key: 7,
                                    command: "performance_improvement"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_30, [
                                        _cache[20] || (_cache[20] = _createElementVNode("div", { class: "mr-20 flex-center" }, [
                                          _createElementVNode("img", {
                                            src: _imports_9,
                                            class: "icon-32",
                                            alt: "OKR Generator"
                                          })
                                        ], -1)),
                                        _createElementVNode("div", null, [
                                          _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.$t("performance_improvement.title")), 1),
                                          _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.$t("performance_improvement.short_desc")), 1)
                                        ])
                                      ])
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        default: _withCtx(() => [
                          _cache[21] || (_cache[21] = _createElementVNode("span", { class: "el-dropdown-link" }, [
                            _createElementVNode("div", { class: "label flex-center" }, [
                              _createElementVNode("div", null, [
                                _createElementVNode("div", {
                                  class: "max-m-none left",
                                  style: {"color":"#696c80"}
                                }, _toDisplayString(`My Toolbox`))
                              ]),
                              _createElementVNode("img", {
                                src: _imports_1,
                                class: "icon-20 ml-16",
                                alt: "icons_arrow_down_black_24px"
                              })
                            ])
                          ], -1))
                        ]),
                        _: 1
                      }, 8, ["onCommand"]))
                    : _createCommentVNode("", true)
                ])
              ], 2)
            ]))
          : _createCommentVNode("", true),
        (_ctx.$route.meta.navbarName !== 'disable')
          ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
              (_ctx.user)
                ? (_openBlock(), _createBlock(_component_el_dropdown, {
                    key: 0,
                    onCommand: _ctx.handleCommand
                  }, {
                    dropdown: _withCtx(() => [
                      _createVNode(_component_el_dropdown_menu, null, {
                        default: _withCtx(() => [
                          (!_ctx.noTeam && _ctx.isUserMode && !_ctx.moreThanOneTeam)
                            ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                key: 0,
                                command: "switch_user_to_team"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_42, [
                                    _cache[25] || (_cache[25] = _createElementVNode("div", { class: "mr-12 flex-center" }, [
                                      _createElementVNode("svg", {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "24",
                                        height: "24",
                                        viewBox: "0 0 24 24",
                                        fill: "none"
                                      }, [
                                        _createElementVNode("path", {
                                          d: "M22 8L22.3536 7.64645L22.7071 8L22.3536 8.35355L22 8ZM3 8.5C2.72386 8.5 2.5 8.27614 2.5 8C2.5 7.72386 2.72386 7.5 3 7.5V8.5ZM18.3536 3.64645L22.3536 7.64645L21.6464 8.35355L17.6464 4.35355L18.3536 3.64645ZM22.3536 8.35355L18.3536 12.3536L17.6464 11.6464L21.6464 7.64645L22.3536 8.35355ZM22 8.5H3V7.5H22V8.5Z",
                                          fill: "#222222"
                                        }),
                                        _createElementVNode("path", {
                                          d: "M2 16L1.64645 15.6464L1.29289 16L1.64645 16.3536L2 16ZM11 16.5C11.2761 16.5 11.5 16.2761 11.5 16C11.5 15.7239 11.2761 15.5 11 15.5L11 16.5ZM5.64645 11.6464L1.64645 15.6464L2.35355 16.3536L6.35355 12.3536L5.64645 11.6464ZM1.64645 16.3536L5.64645 20.3536L6.35355 19.6464L2.35355 15.6464L1.64645 16.3536ZM2 16.5L11 16.5L11 15.5L2 15.5L2 16.5Z",
                                          fill: "#222222"
                                        })
                                      ])
                                    ], -1)),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("myAccount.Switch_to_Team")), 1)
                                  ])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectableTeams, (team) => {
                            return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                              key: 'team_' + team._id,
                              command: `switch_user_to_team_${team._id}`,
                              disabled: team._id == _ctx.currentTeamId
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", {
                                  class: _normalizeClass(["label menu-dropdown my-account flex-center", `${team._id == _ctx.currentTeamId ? 'disabled' : ''}`])
                                }, [
                                  _cache[26] || (_cache[26] = _createElementVNode("div", { class: "mr-12 flex-center" }, [
                                    _createElementVNode("svg", {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      width: "24",
                                      height: "24",
                                      viewBox: "0 0 24 24",
                                      fill: "none"
                                    }, [
                                      _createElementVNode("path", {
                                        d: "M22 8L22.3536 7.64645L22.7071 8L22.3536 8.35355L22 8ZM3 8.5C2.72386 8.5 2.5 8.27614 2.5 8C2.5 7.72386 2.72386 7.5 3 7.5V8.5ZM18.3536 3.64645L22.3536 7.64645L21.6464 8.35355L17.6464 4.35355L18.3536 3.64645ZM22.3536 8.35355L18.3536 12.3536L17.6464 11.6464L21.6464 7.64645L22.3536 8.35355ZM22 8.5H3V7.5H22V8.5Z",
                                        fill: "#222222"
                                      }),
                                      _createElementVNode("path", {
                                        d: "M2 16L1.64645 15.6464L1.29289 16L1.64645 16.3536L2 16ZM11 16.5C11.2761 16.5 11.5 16.2761 11.5 16C11.5 15.7239 11.2761 15.5 11 15.5L11 16.5ZM5.64645 11.6464L1.64645 15.6464L2.35355 16.3536L6.35355 12.3536L5.64645 11.6464ZM1.64645 16.3536L5.64645 20.3536L6.35355 19.6464L2.35355 15.6464L1.64645 16.3536ZM2 16.5L11 16.5L11 15.5L2 15.5L2 16.5Z",
                                        fill: "#222222"
                                      })
                                    ])
                                  ], -1)),
                                  _createElementVNode("div", null, _toDisplayString(_ctx.$t("myAccount.Switch_to_Team_Variable", {
                        teamName: team.name,
                      })), 1)
                                ], 2)
                              ]),
                              _: 2
                            }, 1032, ["command", "disabled"]))
                          }), 128)),
                          (!_ctx.isUserMode || (_ctx.isUserMode && _ctx.moreThanOneTeam))
                            ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                key: 1,
                                command: "switch_team_to_user",
                                disabled: _ctx.isPersonal
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(["label menu-dropdown my-account flex-center", `${_ctx.isPersonal ? 'disabled' : ''}`])
                                  }, [
                                    _cache[27] || (_cache[27] = _createElementVNode("div", { class: "mr-12 flex-center" }, [
                                      _createElementVNode("svg", {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "24",
                                        height: "24",
                                        viewBox: "0 0 24 24",
                                        fill: "none"
                                      }, [
                                        _createElementVNode("path", {
                                          d: "M22 8L22.3536 7.64645L22.7071 8L22.3536 8.35355L22 8ZM3 8.5C2.72386 8.5 2.5 8.27614 2.5 8C2.5 7.72386 2.72386 7.5 3 7.5V8.5ZM18.3536 3.64645L22.3536 7.64645L21.6464 8.35355L17.6464 4.35355L18.3536 3.64645ZM22.3536 8.35355L18.3536 12.3536L17.6464 11.6464L21.6464 7.64645L22.3536 8.35355ZM22 8.5H3V7.5H22V8.5Z",
                                          fill: "#222222"
                                        }),
                                        _createElementVNode("path", {
                                          d: "M2 16L1.64645 15.6464L1.29289 16L1.64645 16.3536L2 16ZM11 16.5C11.2761 16.5 11.5 16.2761 11.5 16C11.5 15.7239 11.2761 15.5 11 15.5L11 16.5ZM5.64645 11.6464L1.64645 15.6464L2.35355 16.3536L6.35355 12.3536L5.64645 11.6464ZM1.64645 16.3536L5.64645 20.3536L6.35355 19.6464L2.35355 15.6464L1.64645 16.3536ZM2 16.5L11 16.5L11 15.5L2 15.5L2 16.5Z",
                                          fill: "#222222"
                                        })
                                      ])
                                    ], -1)),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("myAccount.Switch_to_Personal")), 1)
                                  ], 2)
                                ]),
                                _: 1
                              }, 8, ["disabled"]))
                            : _createCommentVNode("", true),
                          (_ctx.user)
                            ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                key: 2,
                                command: "my_account"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_43, [
                                    _cache[28] || (_cache[28] = _createElementVNode("div", { class: "mr-12 flex-center" }, [
                                      _createElementVNode("svg", {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "24",
                                        height: "24",
                                        viewBox: "0 0 24 24",
                                        fill: "none"
                                      }, [
                                        _createElementVNode("circle", {
                                          cx: "12",
                                          cy: "10",
                                          r: "3",
                                          stroke: "#222222",
                                          "stroke-linecap": "round"
                                        }),
                                        _createElementVNode("circle", {
                                          cx: "12",
                                          cy: "12",
                                          r: "9",
                                          stroke: "#222222"
                                        }),
                                        _createElementVNode("path", {
                                          d: "M18 18.7059C17.6461 17.6427 16.8662 16.7033 15.7814 16.0332C14.6966 15.3632 13.3674 15 12 15C10.6326 15 9.30341 15.3632 8.21858 16.0332C7.13375 16.7033 6.35391 17.6427 6 18.7059",
                                          stroke: "#222222",
                                          "stroke-linecap": "round"
                                        })
                                      ])
                                    ], -1)),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("myAccount.My_Account")), 1)
                                  ])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.user)
                            ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                key: 3,
                                command: "help_n_support"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_44, [
                                    _cache[29] || (_cache[29] = _createElementVNode("div", { class: "mr-12 flex-center" }, [
                                      _createElementVNode("svg", {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "24",
                                        height: "24",
                                        viewBox: "0 0 24 24",
                                        fill: "none"
                                      }, [
                                        _createElementVNode("circle", {
                                          cx: "12",
                                          cy: "12",
                                          r: "9",
                                          stroke: "#222222"
                                        }),
                                        _createElementVNode("path", {
                                          d: "M12.5 7.5C12.5 7.77614 12.2761 8 12 8C11.7239 8 11.5 7.77614 11.5 7.5C11.5 7.22386 11.7239 7 12 7C12.2761 7 12.5 7.22386 12.5 7.5Z",
                                          fill: "#222222"
                                        }),
                                        _createElementVNode("path", {
                                          d: "M12 17V10",
                                          stroke: "#222222"
                                        })
                                      ])
                                    ], -1)),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("myAccount.Help_n_Support")), 1)
                                  ])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.user)
                            ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                key: 4,
                                command: "logout"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_45, [
                                    _cache[30] || (_cache[30] = _createElementVNode("div", { class: "mr-12 flex-center" }, [
                                      _createElementVNode("svg", {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "24",
                                        height: "24",
                                        viewBox: "0 0 24 24",
                                        fill: "none"
                                      }, [
                                        _createElementVNode("path", {
                                          d: "M2 12L1.60957 11.6877L1.35969 12L1.60957 12.3123L2 12ZM11 12.5C11.2761 12.5 11.5 12.2761 11.5 12C11.5 11.7239 11.2761 11.5 11 11.5V12.5ZM5.60957 6.68765L1.60957 11.6877L2.39043 12.3123L6.39043 7.31235L5.60957 6.68765ZM1.60957 12.3123L5.60957 17.3123L6.39043 16.6877L2.39043 11.6877L1.60957 12.3123ZM2 12.5H11V11.5H2V12.5Z",
                                          fill: "#222222"
                                        }),
                                        _createElementVNode("path", {
                                          d: "M10 8.13193V7.38851C10 5.77017 10 4.961 10.474 4.4015C10.9479 3.84201 11.7461 3.70899 13.3424 3.44293L15.0136 3.1644C18.2567 2.62388 19.8782 2.35363 20.9391 3.25232C22 4.15102 22 5.79493 22 9.08276V14.9172C22 18.2051 22 19.849 20.9391 20.7477C19.8782 21.6464 18.2567 21.3761 15.0136 20.8356L13.3424 20.5571C11.7461 20.291 10.9479 20.158 10.474 19.5985C10 19.039 10 18.2298 10 16.6115V16.066",
                                          stroke: "#222222"
                                        })
                                      ])
                                    ], -1)),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("myAccount.Log_out")), 1)
                                  ])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_34, [
                        _createElementVNode("div", _hoisted_35, [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.userDisplayName), 1),
                            (_ctx.quota)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: "max-m-none fs-12 right fw-500",
                                  style: {"line-height":"1.7rem"},
                                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.gotoPaymentPage && _ctx.gotoPaymentPage(...args)))
                                }, [
                                  (_ctx.quota.unlimited)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_37, _cache[22] || (_cache[22] = [
                                        _createElementVNode("span", { class: "fc-primary link" }, _toDisplayString("Unlimited"), -1)
                                      ])))
                                    : _createCommentVNode("", true),
                                  (!_ctx.quota.unlimited && _ctx.quota.quota_remaining)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                                        _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.$t("myAccount.__remaining")) + " " + _toDisplayString(_ctx.quota.quota_remaining) + " " + _toDisplayString(_ctx.$t(
                          _ctx.quota.quota_remaining > 1
                            ? "myAccount.credits"
                            : "myAccount.credit"
                        )) + " " + _toDisplayString(_ctx.$t("myAccount.remaining")), 1)
                                      ]))
                                    : _createCommentVNode("", true),
                                  (!_ctx.quota.unlimited && !_ctx.quota.quota_remaining)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                                        _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.$t("myAccount.__remaining")) + " " + _toDisplayString(_ctx.quota.quota_remaining) + " " + _toDisplayString(_ctx.$t("myAccount.credits")) + " " + _toDisplayString(_ctx.$t("myAccount.remaining")), 1),
                                        _cache[23] || (_cache[23] = _createTextVNode("  ")),
                                        _createElementVNode("a", {
                                          class: "link black",
                                          href: "#",
                                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.push('/account/plan-billing')))
                                        }, _toDisplayString(_ctx.$t("myAccount.Add_Credit")), 1)
                                      ]))
                                    : _createCommentVNode("", true)
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _cache[24] || (_cache[24] = _createElementVNode("img", {
                            src: _imports_1,
                            class: "icon-20 ml-16",
                            alt: "icons_arrow_down_black_24px"
                          }, null, -1))
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["onCommand"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_46, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.helpAndSupportModalStatus,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.helpAndSupportModalStatus) = $event)),
        width: "560px",
        ref: "payAsYouGoModalModal"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_47, [
            _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.$t("myAccount.Need_Help")), 1),
            _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.$t("myAccount.Please_leave_us_a_message")), 1),
            _createElementVNode("div", {
              class: _normalizeClass(["input-container-02 textarea", `${_ctx.errors.needHelpMessage ? 'error' : ''}`])
            }, [
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.needHelpMessage) = $event)),
                placeholder: _ctx.$t('myAccount.Write_your_message_here')
              }, null, 8, _hoisted_50), [
                [_vModelText, _ctx.needHelpMessage]
              ]),
              _createElementVNode("label", _hoisted_51, _toDisplayString(_ctx.$t("myAccount.Message")), 1)
            ], 2),
            _createElementVNode("div", _hoisted_52, [
              _createElementVNode("input", {
                type: "file",
                onChange: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.handleFileUpload && _ctx.handleFileUpload(...args))),
                accept: ".png, .jpg, .jpeg",
                name: "logoCompany",
                class: "input-file"
              }, null, 32),
              (_ctx.isImage)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.previewUrl,
                    alt: "Selected File Preview",
                    class: "image-upload"
                  }, null, 8, _hoisted_53))
                : _createCommentVNode("", true),
              (!_ctx.isImage)
                ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                    _cache[31] || (_cache[31] = _createElementVNode("div", { class: "" }, [
                      _createElementVNode("img", {
                        src: _imports_10,
                        alt: "icons_upload_24px"
                      })
                    ], -1)),
                    _createElementVNode("div", _hoisted_55, _toDisplayString(_ctx.$t("myAccount.Upload_an_attachment")), 1),
                    _createElementVNode("div", _hoisted_56, [
                      _createTextVNode(_toDisplayString(_ctx.$t("createAccount.Drag_drop_or")) + " ", 1),
                      _createElementVNode("span", _hoisted_57, _toDisplayString(_ctx.$t("createAccount.choose_file")), 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("createAccount.to_upload")), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_58, [
              _createElementVNode("div", {
                class: _normalizeClass(["btn02 btn-48 btn-primary flex-mid mr-12", { 'disable-true': false }]),
                style: {"width":"72px"},
                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.submitHelpAndSupport && _ctx.submitHelpAndSupport(...args)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Submit")), 1)
              ]),
              _createElementVNode("div", {
                class: "btn-48 flex-mid btn02-outline",
                style: {"width":"72px"},
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.helpAndSupportModalStatus = false))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 64))
}